import { gql } from "@apollo/client";

import { USER_FRAGMENT } from "graphql/user/fragments";

export const FEED_GROUP_BASIC_FRAGMENT = gql`
  fragment FeedGroupBasic_Fragment on FeedGroup {
    __typename
    id
    slug
    name
    deletedAt
    description
    imageUrl
    totalFollowers
    followers(first: 5) {
      edges {
        node {
          ...User_Fragment
        }
      }
    }
    type
    hasAccess
    restrictedId
    openRequests {
      __typename
      ... on FeedGroupInvite {
        id
        status
      }
      ... on FeedGroupJoinRequest {
        id
        status
      }
    }
  }
  ${USER_FRAGMENT}
`;

export const FEED_GROUP_FULL_FRAGMENT = gql`
  fragment FeedGroupFull_Fragment on FeedGroup {
    id
    community {
      code
    }
    slug
    name
    description
    imageUrl
    leadership {
      user {
        ...User_Fragment
      }
      type
      role
      capabilities
    }
    followers(first: $first, after: $after) {
      edges {
        node {
          ...User_Fragment
        }
        cursor
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
    }
    totalFollowers
    type
    restrictedId
    hasAccess
    openRequests {
      ... on FeedGroupInvite {
        id
        invitedAt
        status
      }
      ... on FeedGroupJoinRequest {
        id
        requestedAt
        status
      }
    }
  }
  ${USER_FRAGMENT}
`;

export const FEED_GROUP_FRAGMENT = gql`
  fragment FeedGroup_Fragment on FeedGroup {
    id
    slug
    name
    description
    imageUrl
    leadership {
      user {
        ...User_Fragment
      }
      type
      role
      capabilities
    }
    followers(first: $first) {
      edges {
        node {
          ...User_Fragment
        }
        cursor
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
    }
    totalFollowers
    type
  }
  ${USER_FRAGMENT}
`;

export const FEED_GROUP_JOIN_REQUEST_FRAGMENT = gql`
  fragment FeedGroupJoinRequest_Fragment on FeedGroupJoinRequest {
    id
    user {
      ...User_Fragment
    }
    status
    requestedAt
    updatedBy {
      ...User_Fragment
    }
    updatedAt
  }
  ${USER_FRAGMENT}
`;

export const FEED_GROUP_INVITE_FRAGMENT = gql`
  fragment FeedGroupInvite_Fragment on FeedGroupInvite {
    id
    group {
      id
      community {
        code
      }
    }
    user {
      ...User_Fragment
    }
    invitedBy {
      ...User_Fragment
    }
    updatedBy {
      ...User_Fragment
    }
    updatedAt
    status
    invitedAt
  }
  ${USER_FRAGMENT}
`;
