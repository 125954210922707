const getBrowserData = () => {
  const ua = navigator.userAgent;
  // eslint-disable-next-line sort-vars
  let tem,
    // eslint-disable-next-line sort-vars
    M =
      ua.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return { name: "IE", version: tem[1] || "" };
  }
  if (M[1] === "Chrome") {
    tem = ua.match(/\bOPR|Edge\/(\d+)/);
    if (tem != null) {
      return { name: "Opera", version: tem[1] };
    }
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
  // eslint-disable-next-line no-cond-assign
  if ((tem = ua.match(/version\/(\d+)/i)) != null) {
    M.splice(1, 1, tem[1]);
  }
  return {
    name: M[0],
    version: M[1],
  };
};

export default function useBrowser() {
  const { userAgent } = navigator;
  let browserName;
  let isChrome, isEdge, isFirefox, isOpera, isSafari;

  const browser = getBrowserData();

  if (userAgent.match(/iPad|iPhone/i)) {
    browserName = "safari";
    isSafari = true;
  } else if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = "chrome";
    isChrome = true;
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserName = "firefox";
    isFirefox = true;
  } else if (userAgent.match(/safari|iPad|iPhone/i)) {
    browserName = "safari";
    isSafari = true;
  } else if (userAgent.match(/opr\//i)) {
    browserName = "opera";
    isOpera = true;
  } else if (userAgent.match(/edg/i)) {
    browserName = "edge";
    isEdge = true;
  } else {
    browserName = "No browser detection";
  }

  return {
    browserName,
    isChrome,
    isFirefox,
    isSafari,
    isOpera,
    isEdge,
    browser,
  };
}
