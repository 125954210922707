import resizeImage from "lib/common/resizeImage";

export default function externalImageLoader({
  height,
  src,
  srcSetWidth,
  width,
}) {
  // 1000 px is the the max our resize api will size to
  const maxPx = 1000;
  // srcSetWidth is the width that NextJs will attempt to scale to for the srcSets it generates
  let w = srcSetWidth;
  // Adjust the height proportionally to the srcSetWidth
  let h = Math.round((height / width) * srcSetWidth);

  // Adjust the height proportionally if the width is over the max
  if (w > maxPx) {
    w = 1000;
    h = Math.round((height / width) * maxPx);
  }

  // Adjust the width proportionally if the height is over the max
  if (h > maxPx) {
    h = 1000;
    w = Math.round((width / height) * maxPx);
  }

  return resizeImage(src, w, h);
}
