import fetch from "isomorphic-unfetch";
import { useMemo } from "react";
import { SWRConfig } from "swr";
import { createContext, useContextSelector } from "use-context-selector";

import useAuth from "hooks/useAuth";
import useSite from "hooks/useSite";
import useStreamFeedClient from "hooks/useStreamFeedClient";

export const AppContext = createContext({});

const swrConfig = {
  refreshInterval: 0,
  fetcher: (resource, init) => fetch(resource, init).then((res) => res.json()),
};

export function AppProvider({ env, getSite, isMobile, children, snackbarRef }) {
  const { config: site, ConfigHead } = useSite(getSite);
  const auth = useAuth();
  const { user } = auth;

  const streamFeedToken = user?.externalTokens?.getStreamFeed;
  const feedClient = useStreamFeedClient(streamFeedToken, site.stream);

  const value = useMemo(
    () => ({
      auth,
      env,
      feedClient,
      isMobile,
      site,
      snackbarRef,
    }),
    [auth, env, feedClient, isMobile, site, snackbarRef]
  );

  return (
    <AppContext.Provider value={value}>
      <ConfigHead />
      <SWRConfig value={swrConfig}>{children}</SWRConfig>
    </AppContext.Provider>
  );
}

export const useApp = (selector) =>
  useContextSelector(AppContext, (state) =>
    selector ? state[selector] : state
  );
