import markdownInit from "lib/markdown/markdownInit";

function stripPositions(node) {
  const { children, ...rest } = node;
  if (children) {
    rest.children = children.map(stripPositions);
  }
  return rest;
}

export default function parseMarkdown(message = "") {
  return stripPositions(markdownInit.Parser(message));
}
