import { SvgIcon } from "@mui/material";

export default function PersonSearch(props) {
  return (
    <SvgIcon height="20" viewBox="0 0 20 20" width="20" {...props}>
      <path
        clipRule="evenodd"
        d="M8 9a4 4 0 100-8 4 4 0 000 8zm.35 2.01C5.62 10.91 0 12.27 0 15v2h9.54c-2.47-2.76-1.23-5.89-1.19-5.99zm9.08 4.01c.36-.59.57-1.28.57-2.02 0-2.21-1.79-4-4-4s-4 1.79-4 4 1.79 4 4 4c.74 0 1.43-.22 2.02-.57L18.59 19 20 17.59l-2.57-2.57zM14 15c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
}
