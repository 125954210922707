import { ArrowDownward } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";

/*
  HOW TO USE
  1 - Add <HintIndicator/> as child of the component that will genarate the overflow.
  2 - Pass the parent ref to HintIndicator as "searchElement" prop.
  3 - The elements that need to be tracked as new notifications must have a boolean
  prop "data-notification".
*/

export default function NavigationHintIndicator({ searchElement }) {
  const [hasOffScreen, setHasOffScreen] = useState(false);

  const indicatorRef = useRef(null);

  const getDomElementsInfo = useCallback(() => {
    const parentElementRect = searchElement.current?.getBoundingClientRect();
    const parentElementScrollPosition = searchElement.current.scrollTop;
    const allElementsWithUnreadContent = searchElement.current.querySelectorAll(
      '[data-notification="true"]'
    );
    const lastElementWithUnreadContent =
      allElementsWithUnreadContent[allElementsWithUnreadContent.length - 1];
    const lastElementRect =
      lastElementWithUnreadContent?.getBoundingClientRect();

    return {
      parentElementRect,
      lastElementRect,
      parentElementScrollPosition,
    };
  }, [searchElement]);

  const checkIfHasElementOffScreen = useCallback(() => {
    if (searchElement.current) {
      const {
        parentElementRect,
        lastElementRect,
        parentElementScrollPosition,
      } = getDomElementsInfo();

      if (!parentElementRect || !lastElementRect) return;

      if (
        (parentElementRect?.height || 0) + parentElementScrollPosition <
        lastElementRect?.top
      ) {
        setHasOffScreen(true);
      } else {
        setHasOffScreen(false);
      }
    }
  }, [getDomElementsInfo, searchElement]);

  const scrollToElement = () => {
    if (searchElement.current) {
      const {
        parentElementRect,
        lastElementRect,
        parentElementScrollPosition,
      } = getDomElementsInfo();

      if (
        parentElementRect.height + parentElementScrollPosition <
        lastElementRect.top
      ) {
        searchElement.current.scroll({
          top: Math.abs(
            parentElementRect.height +
              parentElementScrollPosition -
              lastElementRect.top -
              lastElementRect.height
          ),
          behavior: "smooth",
        });
      }
      setHasOffScreen(false);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      checkIfHasElementOffScreen();
    }, 1000);
    return () => clearInterval(interval);
  }, [checkIfHasElementOffScreen]);

  if (!hasOffScreen) return null;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        width: 296,
        bottom: 0,
        margin: "15px auto",
        position: "fixed",
        zIndex: 2,
      }}
    >
      <Button
        ref={indicatorRef}
        primary
        startIcon={<ArrowDownward style={{ fontSize: "18px" }} />}
        sx={{ fontSize: "12px" }}
        variant="contained"
        onClick={scrollToElement}
      >
        Unread messages
      </Button>
    </Box>
  );
}
