import { gql } from "@apollo/client";

import { ME_FULL_FRAGMENT, ME_ONBOARDING_FRAGMENT } from "graphql/me/fragments";

export const ONBOARDING_MY_PROFILE = gql`
  mutation myProfile(
    $avatar: String
    $companyName: String
    $displayPronouns: Boolean
    $industry: String
    $jobTitle: String
    $placeId: String
    $pronouns: String
  ) {
    myProfile(
      input: {
        avatar: $avatar
        personal: { jobTitle: $jobTitle }
        company: { name: $companyName, industry: $industry }
        placeId: $placeId
        pronouns: $pronouns
        displayPronouns: $displayPronouns
      }
    ) {
      ...MeOnboarding_Fragment
    }
  }
  ${ME_ONBOARDING_FRAGMENT}
`;

export const MY_ONBOARDING_MUTATION = gql`
  mutation myOnboarding($input: OnboardingInput!) {
    myOnboard(input: $input)
  }
`;

export const UPDATE_PROFILE = gql`
  mutation myProfile(
    $avatar: String
    $bio: String
    $companyAreasOfFocus: [String]
    $companyDescription: String
    $companyEmployeeCount: String
    $companyFacebookUrl: String
    $companyIndustry: String
    $companyInstagramUrl: String
    $companyLinkedInUrl: String
    $companyName: String
    $companyTwitterUrl: String
    $companyWebsiteUrl: String
    $displayPronouns: Boolean
    $jobTitle: String
    $personalFacebookUrl: String
    $personalInstagramUrl: String
    $personalLinkedInUrl: String
    $personalTwitterUrl: String
    $personalWebsite: String
    $placeId: String
    $pronouns: String
    $skills: [String]
  ) {
    myProfile(
      input: {
        avatar: $avatar
        personal: {
          bio: $bio
          website: $personalWebsite
          jobTitle: $jobTitle
          skills: $skills
        }
        personalSocial: {
          linkedIn: $personalLinkedInUrl
          facebook: $personalFacebookUrl
          twitter: $personalTwitterUrl
          instagram: $personalInstagramUrl
        }
        company: {
          name: $companyName
          description: $companyDescription
          industry: $companyIndustry
          employeeCount: $companyEmployeeCount
          website: $companyWebsiteUrl
          areasOfFocus: $companyAreasOfFocus
        }
        companySocial: {
          facebook: $companyFacebookUrl
          twitter: $companyTwitterUrl
          linkedIn: $companyLinkedInUrl
          instagram: $companyInstagramUrl
        }
        displayPronouns: $displayPronouns
        pronouns: $pronouns
        placeId: $placeId
      }
    ) {
      ...MeFull_Fragment
    }
  }
  ${ME_FULL_FRAGMENT}
`;

export const UPDATE_AVATAR = gql`
  mutation myProfile($avatar: String) {
    myProfile(input: { avatar: $avatar }) {
      profile {
        avatar
      }
    }
  }
`;

export const UPDATE_PROFILE_SETTINGS = gql`
  mutation updateProfileSettings($input: UpdateProfileSettingsInput!) {
    updateProfileSettings(input: $input) {
      isOptedOutOfPublic
    }
  }
`;
