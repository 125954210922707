import { AvatarProps, Avatar as MUIAvatar } from "@mui/material";

import Image from "components/shared/Image";
import getTextInitials from "lib/user/getTextInitials";

type Props = {
  alt?: string;
  height?: number;
  priority?: boolean;
  src?: string;
  width?: number;
} & AvatarProps;

export default function Avatar({
  alt = "",
  height = 40,
  priority = false,
  src,
  width = 40,
  ...props
}: Props) {
  if (!src) {
    if (!alt) {
      return <MUIAvatar sx={{ width, height }} {...props} />;
    }
    return (
      <MUIAvatar sx={{ width, height }} {...props}>
        {getTextInitials(alt)}
      </MUIAvatar>
    );
  }

  return (
    <MUIAvatar {...props}>
      <Image
        externalSrc
        alt={alt}
        height={height}
        priority={priority}
        src={src}
        width={width}
      />
    </MUIAvatar>
  );
}
