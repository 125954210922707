import { useQuery } from "@apollo/client";

import { ME_QUERY } from "graphql/me/queries";

export default function useMe() {
  const { data, loading, error } = useQuery(ME_QUERY);

  return {
    ...data?.me,
    error,
    loading,
  };
}
