import { ApolloProvider } from "@apollo/client";
import {
  connectAsUser,
  initializeClient,
  useInitializedState,
} from "@communityco/chat-state";
import { GlobalStyles, useMediaQuery } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import * as snippet from "@segment/snippet";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { withLDProvider } from "launchdarkly-react-client-sdk";
import App from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";

import React, { useEffect, useRef, useState } from "react";
import parser from "ua-parser-js";

import ErrorBoundary from "components/errors/ErrorBoundary";
import Layout from "components/shared/AppLayout";
import ProtectedPage from "components/shared/ProtectedPage";
import Snackbar from "components/shared/Snackbar";
import useIdentity from "hooks/useIdentity";
import useSite from "hooks/useSite";
import createClient from "lib/apollo/createClient";
import getRole from "lib/auth/getRole";

import { AppProvider, useApp } from "lib/common/appProvider";
import { shouldHideChat } from "lib/concierge/all-chat";
import {
  hideFlexChat,
  initializeFlexChat,
  showFlexChat,
} from "lib/concierge/flex-chat";
import {
  hideZendeskChat,
  initializeZendeskChat,
  showZendeskChat,
} from "lib/concierge/zendesk-chat";
import StreamProvider from "lib/motorcade/providers/stream";
import trackPage from "lib/segment/trackPage";
import baseUrl from "lib/site/baseUrl";
import getSiteConfig from "lib/site/getSiteConfig";
import theme from "styles/theme";
import "styles/global.css";

const Wrapper = ({ children }) => children;

const queryClient = new QueryClient();

function PageLayout({ Component, pageProps, site }) {
  const {
    layoutProps = {},
    isPublic = false,
    Layout: ComponentLayout = Layout,
  } = Component;
  const Protected = isPublic ? Wrapper : ProtectedPage;
  const { user } = useApp("auth");
  const [flexChatInitialized, setFlexChatInitialized] = useState(false);
  const [zendeskChatInitialized, setZendeskChatInitialized] = useState(false);
  const { error: chatInitializationError } = useInitializedState();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));

  const streamChatToken = user?.externalTokens?.getStreamChat;

  const { asPath } = useRouter();
  const { stream, loginUrl } = site;
  const { brandCode, communityCode, gate, roles, userId } = useIdentity();
  const { apiKey: streamApiKey } = stream;

  function getLoginUrl(redirect) {
    return `${loginUrl}?redirect=${encodeURIComponent(redirect)}`;
  }

  useEffect(() => {
    // On initial load the user object is derived from the jwt and is then shortly
    // after replaced with the the ME graph query response. The ME data is what we
    // want here so checking for __typename ensures that query has resolved
    if (user.__typename) {
      const joinedAtISO = user?.joinedAt
        ? new Date(Number(user?.joinedAt)).toISOString()
        : "";
      window?.analytics?.identify(user.uid, {
        email: user.email,
        community_code: communityCode,
        role: getRole(roles),
        firstName: user?.name?.first,
        lastName: user?.name?.last,
        industry: user?.profile?.company?.industry,
        jobTitle: user?.profile?.jobTitle,
        hasAvatar: user?.profile?.avatar ? "Yes" : "No",
        lastAccessed: user?.engagement?.lastAccessed?.anyDevice,
        joinedAt: joinedAtISO,
        epLastAnsweredAt:
          user?.engagement?.products?.expertPanels?.lastAnsweredAt,
        epLastPublishedAt:
          user?.engagement?.products?.expertPanels?.lastPublishedAt,
        articlesLastEngagedAt:
          user?.engagement?.products?.articles?.lastEngagedAt,
        articlesLastPublishedAt:
          user?.engagement?.products?.articles?.lastPublishedAt,
        isMemberLeader: user?.myGroups?.groupsILead?.length > 0 ? "Yes" : "No",
        location: user?.profile?.location?.familiarName,
        hasMobileApp: user?.engagement?.hasMobileApp,
        membershipTier: user?.membershipTier,
        groupsILead:
          user?.myGroups?.groupsILead?.map((group) => group.groupId) || [],
        isDunning: gate === "dunning",
      });

      const hideChat = shouldHideChat({ isXs, path: asPath });

      if (site && site.twilio) {
        initializeFlexChat({
          uid: user.uid,
          email: user.email,
          name: user.name.fullName,
          brandCode,
          communityCode,
          accountSid: site.twilio?.accountSid,
          flexFlowSid: site.twilio?.flexSid,
          conciergeEmail: site.concierge?.email,
          hide: hideChat,
        });
        setFlexChatInitialized(true);
      } else if (site && site.zendesk) {
        initializeZendeskChat({
          apiKey: site.zendesk.apiKey,
          name: user.name.fullName,
          email: user.email,
          hide: hideChat,
          communityCode,
          brandCode,
        });
        setZendeskChatInitialized(true);
      }

      // Contextualize data sent to newrelic with user data
      if (typeof window.newrelic === "object") {
        window.newrelic.setCustomAttribute("userId", user.uid);
        window.newrelic.setCustomAttribute("email", user.email);
        window.newrelic.setCustomAttribute("brandCode", brandCode);
        window.newrelic.setCustomAttribute("communityCode", communityCode);
      }
    }
  }, [user, site, communityCode, roles, brandCode, asPath, isXs, gate]);

  useEffect(() => {
    if (userId) {
      if (!streamApiKey) {
        throw new Error("No Stream API key configured");
      }
      if (streamChatToken) {
        initializeClient(streamApiKey); // chat client
        connectAsUser({ userId, token: streamChatToken, communityCode });
      }
    }
  }, [userId, streamApiKey, streamChatToken, communityCode]);

  // Send chat initialization errors to new relic
  useEffect(() => {
    if (typeof window.newrelic === "object" && chatInitializationError) {
      window.newrelic.noticeError(chatInitializationError);
    }
  }, [chatInitializationError]);

  // Hide Concierge Chat on route change for some routes
  useEffect(() => {
    const hideChat = shouldHideChat({ isXs, path: asPath });
    if (flexChatInitialized) {
      if (hideChat) {
        hideFlexChat();
      } else {
        showFlexChat();
      }
    } else if (zendeskChatInitialized) {
      if (hideChat) {
        hideZendeskChat(brandCode);
      } else {
        showZendeskChat(brandCode);
      }
    }
  }, [brandCode, flexChatInitialized, zendeskChatInitialized, asPath, isXs]);

  return (
    <Protected loginUrl={!isPublic && getLoginUrl(baseUrl(asPath))}>
      <ComponentLayout {...layoutProps}>
        <Component {...pageProps} />
      </ComponentLayout>
    </Protected>
  );
}

function renderSnippet() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { segmentWriteKey } = useApp("site");

  const opts = {
    apiKey: segmentWriteKey,
    // Note: the page option only covers SSR tracking.
    // MyApp useEffect() is used to track other events using `window.analytics.page()`
    page: false,
  };

  return process.env.NODE_ENV === "development"
    ? snippet.max(opts)
    : snippet.min(opts);
}

function AppHead({ site }) {
  const { name } = site;
  return (
    <Head>
      <title>{name}</title>
      <meta
        content="minimum-scale=1, initial-scale=1, maximum-scale=1, width=device-width, user-scalable=0"
        name="viewport"
      />
      <script
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: renderSnippet() }}
        key="segment"
      />
    </Head>
  );
}

function MyApp({ Component, pageProps = {}, env, getSite, isMobile }) {
  const router = useRouter();
  const snackbarRef = useRef(null);
  const { config: site } = useSite(getSite);
  function handleRouteChange() {
    trackPage();
  }

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
    // listen to router changes to register with Segment
    router.events.on("routeChangeComplete", handleRouteChange);

    // --vh variable is for use in place of the vh css length unit due to it's limitations on mobile browsers
    const setVH = () => {
      document
        .querySelector(":root")
        .style.setProperty("--vh", `${window.innerHeight}px`);
    };

    setVH();

    window.addEventListener("resize", setVH);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
      window.removeEventListener("resize", setVH);
    };
  });

  useEffect(() => {
    // fire initial page load
    handleRouteChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ApolloProvider client={createClient(site.graphEndpoint)}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <AppProvider
          env={env}
          getSite={getSite}
          isMobile={isMobile}
          snackbarRef={snackbarRef}
        >
          <StreamProvider config={site.stream}>
            <AppHead site={site} />
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                <GlobalStyles
                  styles={{
                    a: {
                      fontweight: 600,
                      textDecoration: "none",
                      color: "#0b79d0",
                      wordBreak: "break-word",
                    },
                  }}
                />
                <CssBaseline />
                <ErrorBoundary>
                  <Snackbar reference={snackbarRef} />
                  <PageLayout
                    Component={Component}
                    pageProps={pageProps}
                    site={site}
                  />
                </ErrorBoundary>
              </ThemeProvider>
            </StyledEngineProvider>
          </StreamProvider>
        </AppProvider>
      </QueryClientProvider>
    </ApolloProvider>
  );
}

MyApp.getInitialProps = async (app) => {
  const {
    ctx: { res, req },
  } = app;

  const initialProps = await App.getInitialProps(app);

  const ua = parser(req?.headers["user-agent"]);
  const isMobile =
    ua?.device?.type === "mobile" || ua?.device?.type === "tablet";

  let siteConfig;

  const getSite = () => siteConfig;

  if (req) {
    siteConfig = await getSiteConfig(req.headers.host);
    if (!siteConfig) {
      res.writeHead(404);
      res.end();
    }
    res.setHeader(
      "Cache-Control",
      "no-cache, no-store, max-age=0, must-revalidate"
    );
  }

  return { ...initialProps, env: process.env.CCO_ENV, getSite, isMobile };
};

export default withLDProvider({
  clientSideID: process.env.LAUNCHDARKLY_SDK_CLIENT_SIDE_ID,
})(MyApp);
