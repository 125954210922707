import { useFlags } from "launchdarkly-react-client-sdk";

import useIdentity from "./useIdentity";

type HasMaintenanceAccess = {
  hasFeatureAccess: (feature: string) => boolean;
  hasRouteAccess: (route: string) => boolean;
  isInMaintenanceMode: boolean;
};

const entitlementsRoutes = {
  events: ["/events"],
  "exec perks": ["/exec"],
  "expert panels": [
    "/publishing/expert-panels",
    "/publishing/expert-panels/[questionId]",
  ],
  groups: ["/groups", "/groups/[slug]"],
  home: ["/", "/activities/[activityId]"],
  "member directory": ["/members"],
  messages: ["/messages", "/messages/[channelId]", "/messages/new"],
  profile: ["/profile", "/profile/[profileId]"],
  publishing: ["/publishing"],
  settings: ["/settings"],
  "refer members": ["/refer"],
};

const alwaysAllowedRoutes = ["/dunning"];
const alwaysAllowedFeatures = ["logout"];

export default function useAccess(): HasMaintenanceAccess {
  const { permMaintenanceMembershipEntitlements } = useFlags();
  const { gate } = useIdentity();

  const normalizedEntitlements =
    permMaintenanceMembershipEntitlements?.map((entitlement) =>
      entitlement.toLowerCase()
    ) || [];
  normalizedEntitlements.push(...alwaysAllowedFeatures);
  const entitledRoutes = Object.keys(entitlementsRoutes).reduce(
    (acc, entitlement) => {
      if (normalizedEntitlements?.includes(entitlement)) {
        return [...acc, ...entitlementsRoutes[entitlement]];
      }
      return acc;
    },
    alwaysAllowedRoutes
  );

  const isInMaintenanceMode = gate.includes("maintenance");

  return {
    hasFeatureAccess: (feature) =>
      !isInMaintenanceMode
        ? true
        : normalizedEntitlements?.includes(feature.toLowerCase()) || false,
    hasRouteAccess: (route) =>
      !isInMaintenanceMode ? true : entitledRoutes.includes(route) || false,
    isInMaintenanceMode,
  };
}
