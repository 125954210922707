import { useMutation } from "@apollo/client";
import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  styled,
} from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";

import LocationAutocomplete from "components/shared/LocationAutocomplete";
import { UPDATE_PROFILE } from "graphql/profile/mutations";
import { USER_PROFILE_FRAGMENT } from "graphql/user/fragments";
import { useApp } from "lib/common/appProvider";

const StyledList = styled("ul")(({ theme }) => ({
  listStyle: "none",
  padding: "0",
  margin: theme.spacing(2),

  "& > li": {
    position: "relative",
    paddingLeft: theme.spacing(2),
  },

  "& > li::before": {
    content: "'•'",
    position: "absolute",
    left: theme.spacing(-1),
  },
}));

export default function UpdateLocationDialog() {
  const [placeId, setPlaceId] = useState("");
  const snackbarRef = useApp("snackbarRef");

  const [updateProfile, { loading }] = useMutation(UPDATE_PROFILE, {
    update(cache, { data: { myProfile } }) {
      cache.modify({
        id: cache.identify(myProfile),
        fields: {
          profile() {
            const updatedProfile = cache.writeFragment({
              data: myProfile,
              fragment: USER_PROFILE_FRAGMENT,
            });
            return updatedProfile;
          },
        },
      });
    },
  });

  const handleUpdate = async () => {
    if (!placeId) return;
    try {
      await updateProfile({
        variables: {
          placeId,
        },
      });
      snackbarRef?.current?.callSnackbar(
        "You have successfully updated your location.",
        "success",
        "Congratulations!"
      );
    } catch (error) {
      snackbarRef?.current?.callSnackbar(
        "An error has occurred.  Please try again.",
        "error"
      );
    }
  };

  return (
    <Dialog keepMounted open maxWidth="sm">
      <DialogTitle>Confirm your location</DialogTitle>
      <DialogContent dividers sx={{ borderBottom: 0 }}>
        <DialogContentText>
          <Typography variant="subtitle1">
            We’re pleased to offer you an improved, more diverse list of
            locations to choose from!
          </Typography>
          <Typography variant="body2">
            <StyledList>
              <li>
                If your previous location selection was accurate, you may choose
                the same location today.
              </li>
              <li>
                Make a more precise selection if your location was not
                previously available.
              </li>
              <li>If desired, simply choose your nearest major metro area.</li>
              <li>Update your location at any time in your profile.</li>
            </StyledList>
          </Typography>
        </DialogContentText>

        <Box mb={1} mt={3}>
          <LocationAutocomplete
            required
            onChange={(newVal) => setPlaceId(newVal)}
          />
        </Box>

        <Typography color="textSecondary" variant="caption">
          Your privacy is important to us. Location data is only used to enhance
          your experience in our platform; it is never sold or distributed.
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{
          px: 4,
          pb: 3,
        }}
      >
        <LoadingButton
          className="pendo_web-update-location-prompt"
          loading={loading}
          variant="contained"
          onClick={handleUpdate}
        >
          Update
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
