import { useQuery, useQueryClient } from "@tanstack/react-query";
import { print } from "graphql";

import { isNew } from "components/expert-panels/helpers";
import { EXPERT_PANEL_ACTIVE_QUESTIONS } from "graphql/expert-panels/queries";
import { useApp } from "lib/common/appProvider";

async function fetchExpertPanelActiveQuestions(graphEndpoint: string) {
  const response = await fetch(graphEndpoint, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      query: print(EXPERT_PANEL_ACTIVE_QUESTIONS),
    }),
  });

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  return response.json();
}

export default function useExpertPanelOpenQuestions() {
  const { graphEndpoint } = useApp("site");
  const queryClient = useQueryClient();

  const {
    data,
    isLoading: loading,
    error,
  } = useQuery({
    queryKey: ["expertPanelQuestionsActive"],
    queryFn: async () => {
      const seenQuestionIds =
        JSON.parse(localStorage.getItem("seenQuestionIds")) ?? [];
      const closedQuestionIds =
        JSON.parse(localStorage.getItem("closedQuestionIds")) ?? [];

      const res = await fetchExpertPanelActiveQuestions(graphEndpoint);
      const questions = res.data.expertPanelActiveQuestions || [];

      const newUnansweredQuestions =
        questions.filter(
          (question) => isNew(question) && !question.myAnswer.hasAnswered
        ) || [];

      const newUnseenQuestions = newUnansweredQuestions.filter(
        (question) => !seenQuestionIds.includes(question.id)
      );
      const newUnclosedQuestions = newUnansweredQuestions.filter(
        (question) => !closedQuestionIds.includes(question.id)
      );

      return {
        hasNewUnclosedQuestions: !!newUnclosedQuestions.length,
        hasNewUnseenQuestions: !!newUnseenQuestions.length,
        newUnclosedQuestions,
        newUnseenQuestions,
        questions,
      };
    },
  });

  const hasUnansweredQuestions =
    data?.questions.some((question) => !question.myAnswer.hasAnswered) || false;

  function markQuestionsAsSeen() {
    queryClient.setQueryData(["expertPanelQuestionsActive"], (oldData) => {
      localStorage.setItem(
        "seenQuestionIds",
        JSON.stringify(oldData.questions.map((question) => question.id))
      );

      return {
        ...oldData,
        hasNewUnseenQuestions: false,
        newUnseenQuestions: [],
      };
    });
  }

  function markQuestionsAsClosed() {
    queryClient.setQueryData(["expertPanelQuestionsActive"], (oldData) => {
      const closedQuestionIds =
        JSON.parse(localStorage.getItem("closedQuestionIds")) ?? [];

      // Any question IDs in the closedQuestionIds local storage item that are no longer in the active questions list can be safely removed from local storage
      const filteredClosedQuestionIds = closedQuestionIds.filter((id) =>
        oldData.questions.some((newQuestion) => newQuestion.id === id)
      );
      const newCloseQuestionIds = [
        ...filteredClosedQuestionIds,
        ...oldData.newUnclosedQuestions.map((question) => question.id),
      ];

      localStorage.setItem(
        "closedQuestionIds",
        JSON.stringify(newCloseQuestionIds)
      );

      return {
        ...oldData,
        hasNewUnclosedQuestions: false,
        newUnclosedQuestions: [],
      };
    });
  }

  return {
    ...data,
    hasUnansweredQuestions,
    error,
    loading,
    markQuestionsAsClosed,
    markQuestionsAsSeen,
  };
}
