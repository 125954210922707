import { Button } from "@mui/material";
import Router from "next/router";
import React from "react";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    if (typeof window.newrelic === "object") {
      window.newrelic.noticeError(error);
    }
    console.error(error);
    console.error(errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <div>Something went wrong.</div>
          <Button
            variant="contained"
            onClick={() => Router.reload(window.location.pathname)}
          >
            Reload
          </Button>
        </>
      );
    }

    return this.props.children;
  }
}
