import { useRouter } from "next/router";
import { useCallback } from "react";

import useBrowser from "hooks/useBrowser";
import { useApp } from "lib/common/appProvider";
import buildNotificationData from "lib/notifications/buildNotificationData";
import getNotificationLink, {
  getLegacyNotificationLink,
} from "lib/notifications/getNotificationLink";
import legacyBuildNotificationData from "lib/notifications/legacyBuildNotificationData";
import trackEvent from "lib/segment/trackEvent";
import { GetStreamNotification } from "types/activity";
import { User } from "types/User";

import NotificationMenuItem from "./NotificationMenuItem";

type Props = {
  handleMenuClose: () => void;
  notification: GetStreamNotification;
  user: User;
  readNotification: ({ notificationId }: { notificationId: string }) => void;
};

const nonLegacyNotificationVerbs = [
  "view",
  "like",
  "celebrate",
  "insightful",
  "concerned",
];

export default function NotificationMenuItemContainer({
  handleMenuClose = () => null,
  notification,
  user,
  readNotification,
}: Props) {
  const { isSafari, browser } = useBrowser();
  const router = useRouter();
  const isSafari15 = isSafari && browser?.version === "15";

  const { activities, is_read: isRead, updated_at: updatedAt } = notification;
  const site = useApp("site");
  const conciergeEmail = site?.concierge?.email;
  let { analytics, text } = buildNotificationData({ notification });
  let { link, target } = getNotificationLink({ notification, conciergeEmail });

  // Handle legacy notifications, this can eventually be removed but
  // for the time being we don't want old notifications to disappear
  if (
    !notification.verb.includes("notification:") &&
    !nonLegacyNotificationVerbs.includes(notification.verb)
  ) {
    const legacyNotificationData = legacyBuildNotificationData({
      notification,
      user,
      isSafari15,
    });

    const legacyNotificationLink = getLegacyNotificationLink({ notification });

    analytics = legacyNotificationData.analytics;
    text = legacyNotificationData.text;
    link = legacyNotificationLink.link;
    target = legacyNotificationLink.target;
  }

  const pendoClass = analytics?.pendoClass;

  const onClick = useCallback(
    (path: string) => {
      readNotification({ notificationId: notification.id });
      trackEvent({
        category: analytics?.category,
        label: analytics?.label,
        name: "NotificationClick",
        traits: {},
      });
      if (target === "_blank") {
        window.open(path, "_ blank");
      } else {
        router.push(path);
      }
      handleMenuClose();
    },
    [
      analytics?.category,
      analytics?.label,
      handleMenuClose,
      notification.id,
      readNotification,
      router,
      target,
    ]
  );

  // Prevent rendering empty notifications
  if (!text) {
    return null;
  }

  return (
    <NotificationMenuItem
      avatar={activities?.[0]?.actor?.data?.avatar}
      createdAt={updatedAt}
      isRead={isRead}
      link={link}
      name={activities?.[0]?.actor?.data?.name}
      pendoClass={pendoClass}
      text={text}
      verb={notification?.verb}
      onClick={onClick}
    />
  );
}
