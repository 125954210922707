const CONCIERGE_CHAT_DISABLED_PAGES = ["messages", "login"];
const CONCIERGE_CHAT_DISABLED_MOBILE_PAGES = ["messages", "onboarding"];

export function shouldHideChat({ isXs, path }) {
  const DISABLED_PAGES = isXs
    ? CONCIERGE_CHAT_DISABLED_MOBILE_PAGES
    : CONCIERGE_CHAT_DISABLED_PAGES;

  return DISABLED_PAGES.some((excludedPath) => path.includes(excludedPath));
}
