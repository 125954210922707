import { Button } from "@mui/material";
import { Box, BoxProps } from "@mui/system";
import { ReactElement, ReactNode, useCallback, useState } from "react";

import theme from "styles/theme";

type Props = {
  buttonBackgroundColor?: string;
  children: ReactElement;
  collapseText?: string;
  collapsible?: boolean;
  customExpandButton?: ReactNode;
  expandable?: boolean;
  expandText?: string;
  maxLines?: string | number;
} & BoxProps;

export default function Truncate({
  buttonBackgroundColor = theme.palette.background.paper,
  children,
  collapseText = "...see less",
  collapsible = true,
  customExpandButton,
  expandable = true,
  expandText = "...see more",
  maxLines = 3,
  ...rest
}: Props) {
  const [showReadMore, setShowReadMore] = useState(false);
  const [readMoreOpen, setReadMoreOpen] = useState(false);
  const [lineHeight, setLineHeight] = useState("0px");
  const [fontSize, setFontSize] = useState("0px");
  const [letterSpacing, setLetterSpacing] = useState("0px");

  const wrapperRef = useCallback((node) => {
    if (node !== null) {
      const computedStyle = window.getComputedStyle(node.firstChild);
      setLineHeight(computedStyle.lineHeight);
      setFontSize(computedStyle.fontSize);
      setLetterSpacing(computedStyle.letterSpacing);

      if (
        node?.offsetHeight < node?.scrollHeight ||
        node?.offsetWidth < node?.scrollWidth
      ) {
        setShowReadMore(true);
      } else {
        setShowReadMore(false);
      }
    }
  }, []);

  const showExpandButton = expandable && showReadMore && !readMoreOpen;
  const showCollapseButton = collapsible && showReadMore && readMoreOpen;
  const showButton = showExpandButton || showCollapseButton;

  const buttonText = readMoreOpen ? collapseText : expandText;

  const buttonStyle = {
    lineHeight,
    fontSize,
    letterSpacing,
    fontWeight: 600,
    background: buttonBackgroundColor,
    height: lineHeight,
    padding: 0,
    color: "text.secondary",
    borderRadius: 0,
    "&:hover": {
      background: buttonBackgroundColor,
    },
    ...(!readMoreOpen && {
      position: "absolute",
      bottom: 0,
      right: 0,
      "&::before": {
        content: '""',
        position: "absolute",
        top: 0,
        bottom: 0,
        left: "-24px",
        width: "24px",
        background: `linear-gradient(to right, transparent, ${buttonBackgroundColor})`,
      },
    }),
  };

  return (
    <Box {...rest} maxWidth="100%">
      <Box
        ref={wrapperRef}
        sx={{
          position: "relative",
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
          WebkitLineClamp: !readMoreOpen ? `${maxLines}` : "initial",
        }}
      >
        {children}

        {showExpandButton && customExpandButton && (
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              right: 0,
            }}
          >
            {customExpandButton}
          </Box>
        )}

        {showButton && !customExpandButton && (
          <Button
            sx={buttonStyle}
            onClick={() => setReadMoreOpen((prev) => !prev)}
          >
            {buttonText}
          </Button>
        )}
      </Box>
    </Box>
  );
}
