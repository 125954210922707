import { StreamClient, connect } from "getstream";
import { ReactNode, createContext, useMemo, useState } from "react";

import useMe from "hooks/useMe";

type StreamProviderProps = {
  children: ReactNode;
  config: {
    apiKey: string;
    appId: string;
  };
};

type Stream = {
  streamClient: StreamClient | undefined;
  currentFeedQueryKey: string;
  setCurrentFeedQueryKey: (feedQueryKey: string) => void;
};

export const StreamContext = createContext<Stream>({
  streamClient: undefined,
  currentFeedQueryKey: "",
  setCurrentFeedQueryKey: () => null,
});

function StreamProvider({ children, config }: StreamProviderProps) {
  const { externalTokens } = useMe();

  const [streamClient, setStreamClient] = useState<StreamClient>();
  const [currentFeedQueryKey, setCurrentFeedQueryKey] = useState<string>("");

  if (!streamClient && externalTokens?.getStreamFeed) {
    const client = connect(
      config?.apiKey,
      externalTokens?.getStreamFeed,
      config?.appId
    );
    setStreamClient(client);
  }

  const value = useMemo(() => {
    return {
      streamClient,
      currentFeedQueryKey,
      setCurrentFeedQueryKey,
    };
  }, [currentFeedQueryKey, streamClient]);

  return (
    <StreamContext.Provider value={value}>{children}</StreamContext.Provider>
  );
}

export default StreamProvider;
