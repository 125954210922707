import { ADMIN, DEFAULT_IDENTITY, MEMBER, PUBLIC } from "lib/auth/constants";
import getRole from "lib/auth/getRole";
import { Identity, Roles } from "types/User";

function isomorphicDecode(base64Value) {
  if (typeof window !== "undefined") {
    return window.atob(base64Value);
  }
  return Buffer.from(base64Value, "base64").toString("utf-8");
}

export default function jwtToIdentity(jwtValue): Identity {
  if (!jwtValue) {
    return DEFAULT_IDENTITY;
  }

  try {
    const [, middle] = jwtValue.split(".");
    const payload = isomorphicDecode(middle);
    const {
      aud,
      sub,
      brd: brandCode,
      com: communityCode,
      loc: locationCode,
      gate,
    } = JSON.parse(payload);

    const audiences = Array.isArray(aud) ? aud : [aud];
    const roles: Roles[] = [PUBLIC];
    if (audiences.includes("/graphql")) {
      roles[0] = audiences.includes("/graphqlAdmin") ? ADMIN : MEMBER;
    }

    const userId =
      typeof sub === "string" && sub.startsWith("u:") && sub.slice(2);

    const role: Roles = getRole(roles);

    return {
      brandCode,
      communityCode,
      gate,
      hasRole: (_role: Roles) => roles.includes(_role),
      isLoggedIn: Boolean(userId),
      locationCode,
      roles,
      userId,
      uid: userId,
      role,
    };
  } catch (err) {
    return DEFAULT_IDENTITY;
  }
}
