import {
  AccountCircleOutlined,
  ArrowDropDown,
  ExitToApp,
  Settings,
} from "@mui/icons-material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {
  Box,
  Button,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Skeleton,
  Typography,
} from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";
import Link from "next/link";
import { useRef, useState } from "react";

import Avatar from "components/shared/Avatar";
import useAccess from "hooks/useAccess";
import useIdentity from "hooks/useIdentity";
import useMe from "hooks/useMe";
import { useApp } from "lib/common/appProvider";
import baseUrl from "lib/site/baseUrl";
import { NavigationItem } from "types/navigation";

const hiddenItems = ["Content Studio"];

type Props = {
  items: NavigationItem[];
};

export default function AppHeaderUserMenu({ items }: Props) {
  const [open, setOpen] = useState(false);
  const userButtonRef = useRef();
  const me = useMe();
  const { platformUrl } = useApp("site");
  const { gate } = useIdentity();
  const { permMediaToolkit } = useFlags();
  const { hasFeatureAccess, isInMaintenanceMode } = useAccess();

  const extraItems = [
    ...(permMediaToolkit === true
      ? [
          {
            label: "Media toolkit",
            link: baseUrl("/media-toolkit"),
            newWindow: false,
            pendoClassName: "pendo_web-dropdown-menu-mediatoolkit",
          },
        ]
      : []),
  ];

  const toggleOpen = () => setOpen(!open);

  const navItems = [extraItems, items].flat();
  const filteredItems = navItems.filter(
    ({ label }) => !hiddenItems.includes(label)
  );

  const bottomLinks = [
    ...(gate !== "dunning"
      ? [
          {
            href: "/profile",
            label: "Manage profile",
            icon: <AccountCircleOutlined />,
            pendoClassName: "pendo_web-dropdown-menu-manage-profile",
          },
        ]
      : []),
    {
      href: `/settings`,
      label: "Settings & privacy",
      icon: <Settings />,
      pendoClassName: "pendo_web-dropdown-menu-settings",
    },
    {
      href: `${platformUrl}/logout`,
      label: "Logout",
      icon: <ExitToApp />,
      pendoClassName: "pendo_web-dropdown-menu-logout",
    },
  ];

  if (me?.loading) {
    return (
      <Box display="flex" width="170px">
        <Skeleton height={40} variant="circular" width={40} />

        <Box flexGrow={1} ml={1} py={1}>
          <Skeleton width="100%" />
        </Box>
      </Box>
    );
  }

  return (
    <>
      <Button
        ref={userButtonRef}
        aria-controls="user-menu"
        aria-haspopup="true"
        className="pendo_web-dropdown-menu"
        endIcon={<ArrowDropDown />}
        onClick={toggleOpen}
      >
        <Box
          sx={{
            margin: (theme) => theme.spacing(0.75),
          }}
        >
          <Avatar alt={me?.name?.fullName} src={me?.profile?.avatar} />
        </Box>
        <Typography
          color="textPrimary"
          sx={{ display: { xs: "none", lg: "block" } }}
          variant="subtitle2"
        >
          {me?.name?.fullName}
        </Typography>
      </Button>

      <Menu
        keepMounted
        anchorEl={userButtonRef.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        id="user-menu"
        open={open}
        sx={(theme) => ({
          [theme.breakpoints.down("sm")]: {
            "& .MuiMenu-paper": {
              right: 0,
            },
          },
        })}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={toggleOpen}
      >
        {!gate.includes("dunning") && (
          <div>
            {filteredItems.map(
              ({ label, link, newWindow, pendoClassName = "" }) => {
                const linkProps = {
                  href: link,
                  target: "_self",
                };

                if (newWindow) {
                  linkProps.target = "_blank";
                }

                let className;

                switch (label) {
                  case "Awards":
                    className = "pendo_web-dropdown-menu-awards";
                    break;

                  case "Blog":
                    className = "pendo_web-dropdown-menu-blog";
                    break;

                  case "Coaching":
                    className = "pendo_web-dropdown-menu-coaching";
                    break;

                  default:
                    className = pendoClassName;
                }

                return (
                  <a
                    key={link}
                    className={className}
                    style={{
                      color: "inherit",
                      ...(isInMaintenanceMode && {
                        color: "dimgray",
                        pointerEvents: "none",
                      }),
                    }}
                    {...linkProps}
                  >
                    <MenuItem key={label} onClick={toggleOpen}>
                      <ListItemText>{label}</ListItemText>
                      {isInMaintenanceMode && (
                        <ListItemIcon>
                          <LockOutlinedIcon style={{ color: "#808080" }} />
                        </ListItemIcon>
                      )}
                    </MenuItem>
                  </a>
                );
              }
            )}

            <Divider
              sx={{
                margin: (theme) => theme.spacing(1, 0),
              }}
            />
          </div>
        )}

        {bottomLinks.map(({ pendoClassName, href, label, icon }) => (
          <Link
            key={href}
            className={pendoClassName}
            href={href}
            style={{
              color: "inherit",
              ...(!hasFeatureAccess(label) && {
                color: "dimgray",
                pointerEvents: "none",
              }),
            }}
          >
            <MenuItem onClick={toggleOpen}>
              <ListItemText>
                <Box alignItems="center" display="flex" gap="8px">
                  {icon} {label}
                </Box>
              </ListItemText>
              {!hasFeatureAccess(label) && (
                <ListItemIcon sx={{ ml: 1 }}>
                  <LockOutlinedIcon style={{ color: "#808080" }} />
                </ListItemIcon>
              )}
            </MenuItem>
          </Link>
        ))}
      </Menu>
    </>
  );
}
