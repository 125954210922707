import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

function LinkedinPrimary(props?: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <title>linkedin icon</title>
      <path
        d="M5.364 23.96H.394v-16h4.97v16ZM2.885 5.77a2.885 2.885 0 1 1 0-5.77 2.885 2.885 0 0 1 0 5.77ZM24 23.96h-4.97v-7.776c0-1.863 0-4.25-2.623-4.25s-2.899 2.02-2.899 4.158v7.869H8.485v-16h4.774v2.177a5.246 5.246 0 0 1 4.708-2.623c5.102.04 6.033 3.33 6.033 7.672v8.774Z"
        fill="#333"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
}

export default LinkedinPrimary;
