import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();

export default function baseUrl(url) {
  let localUrl = url;
  if (publicRuntimeConfig.basePath.slice(-1) === "/" && url[0] === "/") {
    localUrl = localUrl.slice(1);
  }
  return `${publicRuntimeConfig.basePath}${localUrl}`;
}

export function noBaseUrl(url) {
  let modifiedUrl = url;
  if (url.startsWith(publicRuntimeConfig.basePath)) {
    modifiedUrl = url.replace(publicRuntimeConfig.basePath, "");
  }
  if (modifiedUrl[0] === "") {
    modifiedUrl = "/";
  }
  return modifiedUrl;
}
