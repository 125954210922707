/* eslint-disable no-case-declarations */
/* eslint-disable camelcase */
import { Button } from "@mui/material";
import { Stack } from "@mui/system";
import React, { Fragment } from "react";

import MarkdownToText from "components/markdown/MarkdownToText";

const buildActorsText = (notification) => {
  const { activities, actor_count } = notification;
  const { actor } = activities?.[0] ?? {};
  const isAggregated = actor_count > 1;

  const actors = isAggregated
    ? activities
        ?.reduce((acc, activity) => {
          const add =
            acc.length <= 2 &&
            activity?.actor?.data?.name &&
            !acc.find(
              (existingActivity) =>
                existingActivity?.actor?.id === activity?.actor?.id
            );
          if (add) {
            acc.push(activity);
          }
          return acc;
        }, [])
        .map((activity) => activity?.actor?.data?.name)
    : [actor?.data?.name];
  const notShownUsers = isAggregated ? actor_count - actors.length : 0;

  const shownUsersText = () => {
    return (
      <>
        {actors.map((shownUser, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Fragment key={index}>
            <span className="semi-bold">
              {shownUser}
              {(index < actors.length - 2 ||
                (index === actors.length - 2 && notShownUsers > 0)) &&
                ", "}
            </span>

            {index === actors.length - 2 && notShownUsers === 0 && " and "}
          </Fragment>
        ))}
      </>
    );
  };

  const extraUsersText = () => {
    if (notShownUsers > 0) {
      return (
        <>
          {" "}
          and{" "}
          <span className="semi-bold">
            {notShownUsers} other{notShownUsers > 1 && "s"}
          </span>
        </>
      );
    }
    return null;
  };

  return (
    <>
      {shownUsersText()}
      {extraUsersText()}
    </>
  );
};

function Follow({ actorsText }) {
  return (
    <>
      {actorsText} started following <span className="semi-bold">you</span>
    </>
  );
}

function View({ name }) {
  return (
    <>
      <span className="semi-bold">{name}</span> viewed your{" "}
      <span className="semi-bold">profile</span>
    </>
  );
}

function GroupPostMention({ actorsText, body, groupName }) {
  return (
    <>
      {actorsText} mentioned you in a <span className="semi-bold">post</span> in{" "}
      <span className="semi-bold">
        {groupName}
        {body ? ": " : ""}
      </span>
      {body && (
        <>
          &quot;
          <MarkdownToText markdown={body} />
          &quot;
        </>
      )}
    </>
  );
}

function GroupPost({ actorsText, body, groupName }) {
  return (
    <>
      {actorsText} posted in{" "}
      <span className="semi-bold">
        {groupName}
        {body ? ":" : ""}
      </span>{" "}
      {body && (
        <>
          &quot;
          <MarkdownToText markdown={body} />
          &quot;
        </>
      )}
    </>
  );
}

function PostMention({ actorsText, body }) {
  return (
    <>
      {actorsText} mentioned you in a <span className="semi-bold">post:</span>{" "}
      &quot;
      <MarkdownToText markdown={body} />
      &quot;
    </>
  );
}

function Post({ actorsText, body }) {
  return (
    <>
      {actorsText} created a <span className="semi-bold">post:</span> &quot;
      <MarkdownToText markdown={body} />
      &quot;
    </>
  );
}

function GroupPostCommentMention({ actorsText, body, groupName }) {
  return (
    <>
      {actorsText} mentioned you in a <span className="semi-bold">comment</span>{" "}
      in <span className="semi-bold">{groupName}:</span> &quot;
      <MarkdownToText markdown={body} />
      &quot;
    </>
  );
}

function GroupPostComment({ actorsText, body, groupName }) {
  return (
    <>
      {actorsText} commented on <span className="semi-bold">your post</span> in{" "}
      <span className="semi-bold">{groupName}:</span> &quot;
      <MarkdownToText markdown={body} />
      &quot;
    </>
  );
}

function GroupPostCommentAlsoCommented({ actorsText, body, groupName }) {
  return (
    <>
      {actorsText} also commented on a <span className="semi-bold">post</span>{" "}
      in <span className="semi-bold">{groupName}: </span>
      &quot;
      <MarkdownToText markdown={body} />
      &quot;
    </>
  );
}

function GroupPostCommentAlsoReacted({ actorsText, body, groupName }) {
  return (
    <>
      {actorsText} commented on a <span className="semi-bold">post</span> you
      reacted to in <span className="semi-bold">{groupName}: </span>
      &quot;
      <MarkdownToText markdown={body} />
      &quot;
    </>
  );
}

function PostCommentAlsoReacted({ actorsText, body }) {
  return (
    <>
      {actorsText} commented on a <span className="semi-bold">post</span> you
      reacted to: &quot;
      <MarkdownToText markdown={body} />
      &quot;
    </>
  );
}

function PostCommentAlsoCommented({ actorsText, body }) {
  return (
    <>
      {actorsText} also commented on a <span className="semi-bold">post: </span>{" "}
      &quot;
      <MarkdownToText markdown={body} />
      &quot;
    </>
  );
}

function PostCommentMention({ actorsText, body }) {
  return (
    <>
      {actorsText} mentioned you in a{" "}
      <span className="semi-bold">comment: </span>
      &quot;
      <MarkdownToText markdown={body} />
      &quot;
    </>
  );
}

function PostComment({ actorsText, body }) {
  return (
    <>
      {actorsText} commented on <span className="semi-bold">your post: </span>
      &quot;
      <MarkdownToText markdown={body} />
      &quot;
    </>
  );
}

function GroupPostReplyMention({ actorsText, body, groupName }) {
  return (
    <>
      {actorsText} mentioned you in a <span className="semi-bold">reply</span>{" "}
      in <span className="semi-bold">{groupName}: </span>
      &quot;
      <MarkdownToText markdown={body} />
      &quot;
    </>
  );
}

function GroupPostReply({ actorsText, body, groupName }) {
  return (
    <>
      {actorsText} replied to <span className="semi-bold">your comment</span> in{" "}
      <span className="semi-bold">{groupName}: </span>
      &quot;
      <MarkdownToText markdown={body} />
      &quot;
    </>
  );
}

function GroupPostReplyAlsoReplied({ actorsText, body, groupName }) {
  return (
    <>
      {actorsText} also replied to a <span className="semi-bold">comment</span>{" "}
      in <span className="semi-bold">{groupName}:</span> &quot;
      <MarkdownToText markdown={body} />
      &quot;
    </>
  );
}

function GroupPostReplyAlsoReacted({ actorsText, body, groupName }) {
  return (
    <>
      {actorsText} replied to a <span className="semi-bold">comment</span> you
      reacted to in <span className="semi-bold">{groupName}:</span> &quot;
      <MarkdownToText markdown={body} />
      &quot;
    </>
  );
}

function PostReplyAlsoReplied({ actorsText, body }) {
  return (
    <>
      {actorsText} also replied to a{" "}
      <span className="semi-bold">comment: </span>
      &quot;
      <MarkdownToText markdown={body} />
      &quot;
    </>
  );
}

function PostReplyAlsoReacted({ actorsText, body }) {
  return (
    <>
      {actorsText} replied to a <span className="semi-bold">comment</span> you
      reacted to: &quot;
      <MarkdownToText markdown={body} />
      &quot;
    </>
  );
}

function PostReplyMention({ actorsText, body }) {
  return (
    <>
      {actorsText} mentioned you in a <span className="semi-bold">reply: </span>
      &quot;
      <MarkdownToText markdown={body} />
      &quot;
    </>
  );
}

function PostReply({ actorsText, body }) {
  return (
    <>
      {actorsText} replied to <span className="semi-bold">your comment: </span>
      &quot;
      <MarkdownToText markdown={body} />
      &quot;
    </>
  );
}

function CommentMention({ actorsText }) {
  return (
    <>
      {actorsText} mentioned you in a <span className="semi-bold">comment</span>
    </>
  );
}

function ReplyMention({ actorsText }) {
  return (
    <>
      {actorsText} mentioned you in a <span className="semi-bold">reply</span>
    </>
  );
}

function GroupCommentReaction({ actorsText, groupName }) {
  return (
    <>
      {actorsText} reacted to <span className="semi-bold">your comment</span> in{" "}
      <span className="semi-bold">{groupName}</span>
    </>
  );
}

function GroupReplyReaction({ actorsText, groupName }) {
  return (
    <>
      {actorsText} reacted to <span className="semi-bold">your reply</span> in{" "}
      <span className="semi-bold">{groupName}</span>
    </>
  );
}

function GroupPostReaction({ actorsText, groupName }) {
  return (
    <>
      {actorsText} reacted to <span className="semi-bold">your post</span> in{" "}
      <span className="semi-bold">{groupName}</span>
    </>
  );
}

function CommentReaction({ actorsText }) {
  return (
    <>
      {actorsText} reacted to <span className="semi-bold">your comment</span>
    </>
  );
}

function ReplyReaction({ actorsText }) {
  return (
    <>
      {actorsText} reacted to <span className="semi-bold">your reply</span>
    </>
  );
}

function PostReaction({ actorsText }) {
  return (
    <>
      {actorsText} reacted to <span className="semi-bold">your post</span>
    </>
  );
}

function Article({ actorsText }) {
  return (
    <>
      {actorsText} published an <span className="semi-bold">article</span>
    </>
  );
}

function GroupExpertPanelRoundup({ groupName }) {
  return (
    <>
      <span className="semi-bold">{groupName} </span>
      published an
      <span className="semi-bold"> Expert Panel</span>
    </>
  );
}

function ExpertPanelQuestions() {
  return (
    <Stack alignItems="start" spacing="8px">
      <span className="semi-bold">
        Expert Panel questions are available to answer
      </span>
      <Button
        component="span"
        sx={{ mb: "4px !important" }}
        variant="contained"
      >
        View questions
      </Button>
    </Stack>
  );
}

function GroupExpertPanelQuestion({ groupName }) {
  return (
    <Stack alignItems="start" spacing="8px">
      <div>
        An
        <span className="semi-bold"> Expert Panel </span>
        is available in
        <span className="semi-bold"> {groupName}</span>
      </div>
      <Button
        component="span"
        sx={{ mb: "4px !important" }}
        variant="contained"
      >
        View question
      </Button>
    </Stack>
  );
}

function Event({ hostName, groupName }) {
  return (
    <Stack alignItems="start" spacing="8px">
      <div>
        {hostName && <span className="semi-bold">{hostName}</span>}
        {hostName ? ` created an Event for ` : `An Event was created for `}
        <span className="semi-bold">{groupName ?? "Everyone"}</span>
      </div>
      <Button
        component="span"
        sx={{ mb: "4px !important" }}
        variant="contained"
      >
        See Event
      </Button>
    </Stack>
  );
}

function Poll({ pollCreator, groupName }) {
  return (
    <Stack alignItems="start" spacing="8px">
      <div>
        <span className="semi-bold">{pollCreator}</span> created a{" "}
        <span className="semi-bold">poll</span> for {groupName || "Everyone"}
      </div>
      <Button
        component="span"
        sx={{ mb: "4px !important" }}
        variant="contained"
      >
        Go to poll
      </Button>
    </Stack>
  );
}

export default function buildNotificationData({
  notification,
  user,
  isSafari15,
}) {
  const { activities, verb } = notification;
  const {
    actor,
    audience,
    body,
    groupRef: { data: { name: groupName } = {} } = {},
    mentions,
    object,
    reaction,
    receiveReason,
    origin,
  } = activities?.[0] ?? {};

  const actorsText = buildActorsText(notification);

  // A users notification feed follows the user feed of another user when they follow them.
  // This allows the user to receive notifications for activity from that user such as new
  // posts they've created. However there are a few things that we don't want to display to the
  // follower such as replies to comments. By checking if the origin is a user feed we can
  // determine if the notification came from a users feed and then use that to conditionally
  // render notifications that may be ambiguous
  const isFromAuthorsFeed = origin === `user:${actor.id}`;

  const isReactionNotificationForUser = reaction?.target_feeds?.includes(
    `notification:${user?.id}`
  );

  switch (verb) {
    case "follow":
      return {
        text: <Follow actorsText={actorsText} />,
        analytics: {
          category: "My Profile Activity",
          label: "Profile Followed",
        },
      };

    case "view":
      return {
        text: <View name={actor?.data?.name} />,
        analytics: {
          category: "My Profile activity",
          label: "Profile Viewed",
        },
      };

    case "post":
      if (audience === "group") {
        if (mentions?.includes(`User:${user?.id}`)) {
          return {
            text: (
              <GroupPostMention
                actorsText={actorsText}
                body={!isSafari15 && object?.data?.body}
                groupName={groupName}
              />
            ),
            analytics: {
              category: "@mention Group activity",
              label: "Group At Mention on Post",
            },
          };
        }

        return {
          text: (
            <GroupPost
              actorsText={actorsText}
              body={!isSafari15 && object?.data?.body}
              groupName={groupName}
            />
          ),
          analytics: {
            category: "My Group post activity",
            label: "Post in My Group",
          },
        };
      }

      if (mentions?.includes(`User:${user?.id}`)) {
        return {
          text: (
            <PostMention actorsText={actorsText} body={object?.data?.body} />
          ),
          analytics: {
            category: "@mention activity",
            label: "At Mention on Post",
          },
        };
      }

      return {
        text: <Post actorsText={actorsText} body={object?.data?.body} />,
        analytics: {
          category: "My following activity",
          label: "Post from Following",
        },
      };

    case "comment":
      if (object?.audience === "group") {
        if (reaction?.data?.mentions?.includes(`User:${user?.id}`)) {
          return {
            text: (
              <GroupPostCommentMention
                actorsText={actorsText}
                body={reaction?.data?.body}
                groupName={object?.groupRef?.data?.name}
              />
            ),
            analytics: {
              category: "@mention Group activity",
              label: "Group At Mention on Comment",
            },
          };
        }

        if (isReactionNotificationForUser) {
          return {
            text: (
              <GroupPostComment
                actorsText={actorsText}
                body={reaction?.data?.body}
                groupName={object?.groupRef?.data?.name}
              />
            ),
            analytics: {
              category: "My Group post activity",
              label: "Group Comment",
            },
          };
        }

        return { text: null };
      }

      if (groupName && !isFromAuthorsFeed) {
        if (receiveReason === "alsoCommented") {
          return {
            text: (
              <GroupPostCommentAlsoCommented
                actorsText={actorsText}
                body={body}
                groupName={groupName}
              />
            ),
            analytics: {
              category: "My Group comment activity",
              label: "Group Comment on Comment",
            },
          };
        }

        if (receiveReason === "reacted") {
          return {
            text: (
              <GroupPostCommentAlsoReacted
                actorsText={actorsText}
                body={body}
                groupName={groupName}
              />
            ),
            analytics: {
              category: "My Group comment activity",
              label: "Group Comment on Post I Reacted to",
            },
          };
        }
      }

      if (!isFromAuthorsFeed) {
        if (receiveReason === "reacted") {
          return {
            text: (
              <PostCommentAlsoReacted actorsText={actorsText} body={body} />
            ),
            analytics: {
              category: "My comment activity",
              label: "Comment on Post I Reacted to",
            },
          };
        }

        if (receiveReason === "alsoCommented") {
          return {
            text: (
              <PostCommentAlsoCommented actorsText={actorsText} body={body} />
            ),
            analytics: {
              category: "My comment activity",
              label: "Comment on Comment",
            },
          };
        }
      }

      if (reaction?.data?.mentions?.includes(`User:${user?.id}`)) {
        return {
          text: (
            <PostCommentMention
              actorsText={actorsText}
              body={reaction?.data?.body}
            />
          ),
          analytics: {
            category: "@mention activity",
            label: "At Mention on Comment",
          },
        };
      }

      if (isReactionNotificationForUser) {
        return {
          text: (
            <PostComment actorsText={actorsText} body={reaction?.data?.body} />
          ),
          analytics: {
            category: "My post activity",
            label: "Comment",
          },
        };
      }

      return { text: null };

    case "reply":
      if (object?.audience === "group") {
        if (reaction?.data?.mentions?.includes(`User:${user?.id}`)) {
          return {
            text: (
              <GroupPostReplyMention
                actorsText={actorsText}
                body={reaction?.data?.body}
                groupName={object?.groupRef?.data?.name}
              />
            ),
            analytics: {
              category: "@mention Group activity",
              label: "Group At Mention on Reply",
            },
          };
        }

        if (isReactionNotificationForUser) {
          return {
            text: (
              <GroupPostReply
                actorsText={actorsText}
                body={reaction?.data?.body}
                groupName={object?.groupRef?.data?.name}
              />
            ),
            analytics: {
              category: "My Group comment activity",
              label: "Group Reply to Comment",
            },
          };
        }

        return { text: null };
      }

      if (groupName && !isFromAuthorsFeed) {
        if (receiveReason === "alsoReplied") {
          return {
            text: (
              <GroupPostReplyAlsoReplied
                actorsText={actorsText}
                body={body}
                groupName={groupName}
              />
            ),
            analytics: {
              category: "My Group comment activity",
              label: "Group Reply to Comment I Replied to",
            },
          };
        }

        if (receiveReason === "reacted") {
          return {
            text: (
              <GroupPostReplyAlsoReacted
                actorsText={actorsText}
                body={body}
                groupName={groupName}
              />
            ),
            analytics: {
              category: "My Group reactions activity",
              label: "Group Reply to Comment I Reacted to",
            },
          };
        }
      }

      if (!isFromAuthorsFeed) {
        if (receiveReason === "alsoReplied") {
          return {
            text: <PostReplyAlsoReplied actorsText={actorsText} body={body} />,
            analytics: {
              category: "My comment activity",
              label: "Reply to Comment I Replied to",
            },
          };
        }

        if (receiveReason === "reacted") {
          return {
            text: <PostReplyAlsoReacted actorsText={actorsText} body={body} />,
            analytics: {
              category: "My reactions activity",
              label: "Reply to Comment I Reacted to",
            },
          };
        }
      }

      if (reaction?.data?.mentions?.includes(`User:${user?.id}`)) {
        return {
          text: (
            <PostReplyMention
              actorsText={actorsText}
              body={reaction?.data?.body}
            />
          ),
          analytics: {
            category: "@mention activity",
            label: "At Mention on Reply",
          },
        };
      }

      if (isReactionNotificationForUser) {
        return {
          text: (
            <PostReply actorsText={actorsText} body={reaction?.data?.body} />
          ),
          analytics: {
            category: "My comment activity",
            label: "Reply to Comment",
          },
        };
      }

      return { text: null };

    case "mention":
      const parsedObject = JSON.parse(object);

      if (parsedObject.mentionFromType === "comment") {
        return {
          text: <CommentMention actorsText={actorsText} />,
          analytics: {
            category: "@mention activity",
            label: "At Mention on Comment",
          },
        };
      }

      if (parsedObject.mentionFromType === "reply") {
        return {
          text: <ReplyMention actorsText={actorsText} />,
          analytics: {
            category: "@mention activity",
            label: "At Mention on Reply",
          },
        };
      }

      return { text: null };

    case "like":
    case "celebrate":
    case "insightful":
    case "concerned":
      if (!isReactionNotificationForUser) {
        return { text: null };
      }

      if (object?.audience === "group") {
        if (reaction?.data?.activityType === "comment") {
          return {
            text: (
              <GroupCommentReaction
                actorsText={actorsText}
                groupName={object?.groupRef?.data?.name}
              />
            ),
            analytics: {
              category: "My Group comment activity",
              label: "Group Reaction to Comment",
            },
          };
        }

        if (reaction?.data?.activityType === "reply") {
          return {
            text: (
              <GroupReplyReaction
                actorsText={actorsText}
                groupName={object?.groupRef?.data?.name}
              />
            ),
            analytics: {
              category: "My Group reactions activity",
              label: "Group Reaction to Reply",
            },
          };
        }

        return {
          text: (
            <GroupPostReaction
              actorsText={actorsText}
              groupName={object?.groupRef?.data?.name}
            />
          ),
          analytics: {
            category: "My Group post activity",
            label: "Group Reaction",
          },
        };
      }

      if (reaction?.data?.activityType === "comment") {
        return {
          text: <CommentReaction actorsText={actorsText} />,
          analytics: {
            category: "My comment activity",
            label: "Reaction to Comment",
          },
        };
      }

      if (reaction?.data?.activityType === "reply") {
        return {
          text: <ReplyReaction actorsText={actorsText} />,
          analytics: {
            category: "My reactions activity",
            label: "Reaction to Reply",
          },
        };
      }

      return {
        text: <PostReaction actorsText={actorsText} />,
        analytics: {
          category: "My post activity",
          label: "Reaction",
        },
      };

    case "article":
      return {
        text: <Article actorsText={actorsText} />,
        analytics: {
          category: "My following activity",
          label: "Article from Following",
        },
      };

    case "expertpanel":
      if (groupName) {
        return {
          text: <GroupExpertPanelRoundup groupName={groupName} />,
          analytics: {
            label: "New Group Expert Panel Roundup",
          },
        };
      }

      return { text: null };

    case "answer_expertpanel":
      return {
        text: <ExpertPanelQuestions />,
        analytics: {
          label: "New Expert Panels",
        },
      };

    case "group_expertpanel":
      return {
        text: <GroupExpertPanelQuestion groupName={groupName} />,
        analytics: {
          label: "New Group Expert Panel",
        },
      };

    case "event":
      const hostName = activities[0]?.host?.data?.name;
      return {
        text: <Event groupName={groupName} hostName={hostName} />,
        analytics: {
          label: groupName
            ? "New Event in a Group"
            : "New Event in the Community",
        },
      };

    case "poll":
      const pollCreator = activities[0]?.actor?.data?.name;
      return {
        text: <Poll groupName={groupName} pollCreator={pollCreator} />,
        analytics: {
          label: "New Poll in a Group",
        },
      };

    default:
      return { text: null };
  }
}
