import { gql } from "@apollo/client";

import {
  EXPERT_PANEL_ANSWER_FRAGMENT,
  EXPERT_PANEL_QUESTION_FRAGMENT,
} from "graphql/expert-panels/fragments";

export const EXPERT_PANEL_ACTIVE_QUESTIONS = gql`
  query expertPanelActiveQuestions {
    expertPanelActiveQuestions {
      ...ExpertPanelQuestion_Fragment
    }
  }
  ${EXPERT_PANEL_QUESTION_FRAGMENT}
`;

export const EXPERT_PANEL_QUESTION = gql`
  query expertPanelQuestion($id: String!, $first: Int, $after: String) {
    expertPanelQuestion(id: $id) {
      ...ExpertPanelQuestion_Fragment
      pagedAnswers(first: $first, after: $after) {
        edges {
          cursor
          node {
            ...ExpertPanelAnswer_Fragment
          }
        }
        pageInfo {
          hasNextPage
        }
      }
    }
  }
  ${EXPERT_PANEL_QUESTION_FRAGMENT}
  ${EXPERT_PANEL_ANSWER_FRAGMENT}
`;

export const EXPERT_PANEL_CLOSED_QUESTIONS = gql`
  query expertPanelClosedQuestions($first: Int, $after: String) {
    expertPanelClosedQuestions(first: $first, after: $after) {
      pageInfo {
        hasNextPage
      }
      edges {
        cursor
        node {
          ...ExpertPanelQuestion_Fragment
        }
      }
    }
  }
  ${EXPERT_PANEL_QUESTION_FRAGMENT}
`;
