import { SvgIcon } from "@mui/material";

export default function Exec(props) {
  return (
    <SvgIcon height="20" viewBox="0 0 20 20" width="20" {...props}>
      <path d="M18.874 14.597A9.957 9.957 0 0019.99 10c0-1.657-.404-3.22-1.117-4.597h-2.278c-.688 0-1.347.273-1.834.76l-3.855 3.854 3.82 3.82c.487.487 1.146.76 1.834.76h2.313z" />
      <path d="M18.07 15.893h-1.51c-1.03 0-2.02-.41-2.75-1.139l-3.82-3.82-3.82 3.82a3.89 3.89 0 01-2.75 1.14H1.91A9.986 9.986 0 009.99 20c3.32 0 6.261-1.617 8.08-4.107z" />
      <path d="M1.108 14.597a9.94 9.94 0 01-1.095-3.925h5.744l1.297-1.296H.01a9.941 9.941 0 011.098-3.973h2.277c.688 0 1.348.273 1.834.76l2.524 2.524.917-.917-2.525-2.524a3.889 3.889 0 00-2.75-1.14H1.911A9.986 9.986 0 019.99 0c3.319 0 6.26 1.617 8.08 4.107h-1.475c-1.032 0-2.02.41-2.75 1.139l-8.592 8.592a2.593 2.593 0 01-1.833.76H1.108z" />
    </SvgIcon>
  );
}
