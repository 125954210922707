import { AUTHORIZATION_COOKIE, DEFAULT_IDENTITY } from "lib/auth/constants";
import jwtToIdentity from "lib/auth/jwtToIdentity";
import { Identity } from "types/User";

let lastCookie;
let jwt = null;
let idObj: Identity = DEFAULT_IDENTITY;

export default function useIdentity(): Identity {
  if (typeof document === "undefined") {
    // if being called server side, return default identity
    return DEFAULT_IDENTITY;
  }
  if (lastCookie !== document.cookie) {
    const match = document.cookie.match(`${AUTHORIZATION_COOKIE}=([^;]+)`);
    const newJwt = match && match[1];
    if (newJwt !== jwt) {
      idObj = jwtToIdentity(newJwt);
      jwt = newJwt;

      if (!idObj?.gate.includes("dunning"))
        localStorage.removeItem("dunningRenewalRequested");
      if (!idObj?.gate.includes("maintenance"))
        localStorage.removeItem("maintenanceRequested");
    }
    lastCookie = document.cookie;
  }
  return idObj;
}
