import { SvgIcon } from "@mui/material";

export default function Article(props) {
  return (
    <SvgIcon height="20" viewBox="0 0 20 20" width="20" {...props}>
      <path
        clipRule="evenodd"
        d="M17.778 0H2.222C1 0 0 1 0 2.222v15.556C0 19 1 20 2.222 20h15.556C19 20 20 19 20 17.778V2.222C20 1 19 0 17.778 0zm-5.556 15.556H4.444v-2.223h7.778v2.223zm3.334-4.445H4.444V8.89h11.112v2.222zm0-4.444H4.444V4.444h11.112v2.223z"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
}
