export default function trackEvent({ category, label, name, traits }) {
  if (!name) {
    throw new Error("The `name` field is required.");
  }

  if (traits) {
    window?.analytics?.track(
      name,
      {
        category,
        label,
        name,
      },
      {
        traits,
      }
    );
  } else {
    window?.analytics?.track(name, {
      category,
      label,
      name,
    });
  }
}
