/* eslint-disable no-case-declarations */
/* eslint-disable camelcase */
import { Button, capitalize } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";

import Truncate from "components/shared/Truncate";
import pluralize from "lib/common/pluralize";

function getActorsString(notification) {
  const MAX_VISIBLE = 3;
  const { activities, actor_count } = notification;

  const names = activities.map((a) => `**${a?.actor?.data?.name}**`);

  if (actor_count === 1) {
    return names[0];
  }

  const uniqueNames = new Set(names);
  const visibleNames = [...uniqueNames].slice(0, MAX_VISIBLE);

  const extra = uniqueNames.size - visibleNames.length;
  if (extra > 0) {
    visibleNames.push(`**${extra} ${pluralize("other", extra)}**`);
  }

  const last = visibleNames.pop();
  return `${visibleNames.join(", ")} and ${last}`;
}

function aggregatedMessageTitle(notification) {
  const actorsText = getActorsString(notification);
  const firstActivity = notification.activities[0];
  const firstActorName = firstActivity?.actor?.data?.name;
  const { title, body } = firstActivity.message;
  const text = title || body;
  const wasOrWere = notification.actor_count > 1 ? "were" : "was";
  return text
    .replace("was", wasOrWere)
    .replace(`**${firstActorName}**`, actorsText);
}

const FormatNotificationText = React.memo(({ text }) => {
  const parts = text.split(/(\*\*[^*]+\*\*)/g);

  return (
    <>
      {parts.map((part, index) => {
        if (part.startsWith("**") && part.endsWith("**")) {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <span key={index} className="semi-bold">
              {part.slice(2, -2)}
            </span>
          );
        }
        // eslint-disable-next-line react/no-array-index-key
        return <span key={index}>{part}</span>;
      })}
    </>
  );
});

function GenericNotification({ title, body, buttonLabel, buttonClass = "" }) {
  return (
    <Stack alignItems="start" spacing="8px">
      <span>
        <Truncate expandable={false} maxLines={3}>
          {title && <FormatNotificationText text={title} />}
          <FormatNotificationText text={body} />
        </Truncate>
      </span>
      {buttonLabel && (
        <Button
          className={buttonClass}
          component="span"
          sx={{ mb: "4px !important" }}
          variant="contained"
        >
          {buttonLabel}
        </Button>
      )}
    </Stack>
  );
}

function getPostNotification(notification, groupName) {
  const mention = notification.activities.find(
    (a) => a.verb === "notification:postMention"
  );

  const title = mention?.message?.title || aggregatedMessageTitle(notification);

  let analytics = {
    category: groupName ? "My Group post activity" : "My following activity",
    label: groupName ? "Post in My Group" : "Post from Following",
    pendoClass: groupName
      ? "pendo_web-notifications-mygroup-member-post"
      : "pendo_web-notifications-someoneifollow-post",
  };

  if (mention) {
    analytics = {
      category: groupName ? "@mention Group activity" : "@mention activity",
      label: groupName ? "@mention activity" : "At Mention on Post",
      pendoClass: groupName
        ? "pendo_web-notifications-group-post-mentionsme"
        : "pendo_web-notifications-post-mentionsme",
    };
  }

  return {
    title,
    analytics,
  };
}

function getCommentNotification(notification, groupName) {
  const { message, verb } = notification.activities[0];
  const isMention = verb === "notification:commentMention";
  const isAlsoCommented = message.title.includes("also commented");
  const isAlsoCommentedOnAPost = /also commented on a \*\*post\*\*/.test(
    message.title
  );
  const isAlsoCommentedOnAPoll = /also commented on a \*\*poll\*\*/.test(
    message.title
  );
  const isReacted = message.title.includes("you reacted to");
  const isCommentedOnMyPost = /commented on your \*\*post\*\*/.test(
    message.title
  );
  const isCommentedOnMyPoll = /commented on your \*\*poll\*\*/.test(
    message.title
  );

  const title = isMention
    ? message?.title
    : aggregatedMessageTitle(notification);

  let analytics = {
    category: groupName ? "My Group post activity" : "My post activity",
    label: groupName ? "Group Comment" : "Comment",
  };

  if (isMention) {
    analytics = {
      category: groupName ? "@mention Group activity" : "@mention activity",
      label: groupName
        ? "Group At Mention on Comment"
        : "At Mention on Comment",
      pendoClass: groupName
        ? "pendo_web-notifications-group-comment-mentionsme"
        : "pendo_web-notifications-comment-mentionsme",
    };
  }

  if (isAlsoCommented) {
    let pendoClass;

    if (isAlsoCommentedOnAPost) {
      pendoClass = groupName
        ? "pendo_web-notifications-group-mycomment-alsocommented"
        : "pendo_web-notifications-mycomment-alsocommented";
    }

    if (isAlsoCommentedOnAPoll) {
      pendoClass = groupName
        ? "pendo_web-notifications-grouppoll-mycomment-alsocommented"
        : "pendo_web-notifications-poll-mycomment-alsocommented";
    }

    analytics = {
      category: groupName ? "My Group comment activity" : "My comment activity",
      label: groupName ? "Group Comment on Comment" : "Comment on Comment",
      pendoClass,
    };
  }

  if (isReacted) {
    analytics = {
      category: groupName ? "My Group comment activity" : "My comment activity",
      label: groupName
        ? "Group Comment on Post I reacted to"
        : "Comment on Post I reacted to",
      pendoClass: groupName
        ? "pendo_web-notifications-group-mypostreaction-comment"
        : "pendo_web-notifications-mypostreaction-comment",
    };
  }

  if (isCommentedOnMyPost) {
    analytics.pendoClass = groupName
      ? "pendo_web-notifications-group-mypost-comment"
      : "pendo_web-notifications-mypost-comment";
  }

  if (isCommentedOnMyPoll) {
    analytics.pendoClass = groupName
      ? "pendo_web-notifications-mygrouppoll-comment"
      : "pendo_web-notifications-mypoll-comment";
  }

  return {
    title,
    analytics,
  };
}

function getReplyNotification(notification, groupName) {
  const { message, verb } = notification.activities[0];
  const isMention = verb === "notification:replyMention";
  const isAlsoReplied = message.title.includes("also replied");
  const isReacted = message.title.includes("you reacted to");
  const isReplyToMyComment = /replied to your \*\*comment\*\*/.test(
    message.title
  );

  const title = isMention
    ? message?.title
    : aggregatedMessageTitle(notification);

  let analytics = {
    category: groupName ? "My Group comment activity" : "My comment activity",
    label: groupName ? "Group Reply to Comment" : "Reply to Comment",
  };

  if (isMention) {
    analytics = {
      category: groupName ? "@mention Group activity" : "@mention activity",
      label: groupName ? "Group At Mention on Reply" : "At Mention on Reply",
      pendoClass: groupName
        ? "pendo_web-notifications-group-reply-mentionsme"
        : "pendo_web-notifications-reply-mentionsme",
    };
  }

  if (isAlsoReplied) {
    analytics = {
      category: groupName ? "My Group comment activity" : "My comment activity",
      label: groupName
        ? "Group Reply to Comment I Replied to"
        : "Reply to Comment I Replied to",
      pendoClass: groupName
        ? "pendo_web-notifications-group-myreply-alsoreplied"
        : "pendo_web-notifications-myreply-alsoreplied",
    };
  }

  if (isReacted) {
    analytics = {
      category: groupName
        ? "My Group reactions activity"
        : "My reactions activity",
      label: groupName
        ? "Group Reply to Comment I Reacted to"
        : "Reply to Comment I Reacted to",
      pendoClass: groupName
        ? "pendo_web-notifications-group-mycommentreaction-reply"
        : "pendo_web-notifications-mycommentreaction-reply",
    };
  }

  if (isReplyToMyComment) {
    analytics.pendoClass = groupName
      ? "pendo_web-notifications-group-mycomment-reply"
      : "pendo_web-notifications-mycomment-reply";
  }

  return {
    title,
    analytics,
  };
}

function getReactionNotification(notification) {
  const { activities } = notification;
  const { reaction, object } = activities[0];
  const { activityType } = reaction?.data || {};
  const actorsText = getActorsString(notification);
  const isGroupNotification = object?.audience === "group";
  let body = `${actorsText} reacted to your **${activityType}**`;

  const analytics = {
    category: `My ${activityType} activity`,
    label: `Reaction${
      activityType && activityType !== "post"
        ? ` to ${capitalize(activityType)}`
        : ""
    }`,
  };

  if (isGroupNotification) {
    analytics.category = `My Group ${activityType} activity`;
    analytics.label = `Group ${analytics.label}`;
    body = `${body} in **${object.groupRef?.data.name || ""}**`;
  }

  if (activityType === "post") {
    analytics.pendoClass = isGroupNotification
      ? "pendo_web-notifications-group-mypost-react"
      : "pendo_web-notifications-mypost-react";
  }

  if (activityType === "poll") {
    analytics.pendoClass = isGroupNotification
      ? "pendo_web-notifications-mygrouppoll-react"
      : "pendo_web-notifications-mypoll-react";
  }

  if (activityType === "reply") {
    analytics.pendoClass = isGroupNotification
      ? "pendo_web-notifications-group-myreply-react"
      : "pendo_web-notifications-myreply-react";
  }

  if (activityType === "comment") {
    analytics.pendoClass = isGroupNotification
      ? "pendo_web-notifications-group-mycomment-react"
      : "pendo_web-notifications-mycomment-react";
  }

  return {
    analytics,
    body,
  };
}

export default function buildNotificationData({ notification }) {
  const { activities, verb } = notification;
  const {
    actor,
    groupRef: { data: { name: groupName } = {} } = {},
    message,
  } = activities?.[0] ?? {};

  switch (verb) {
    case "notification:post":
    case "notification:postMention":
      const postNotification = getPostNotification(notification, groupName);
      return {
        text: (
          <GenericNotification
            body={`${postNotification.title}: "${message.body}"`}
          />
        ),
        analytics: postNotification.analytics,
      };

    case "notification:comment":
    case "notification:commentMention":
      const commentNotification = getCommentNotification(
        notification,
        groupName
      );

      return {
        text: (
          <GenericNotification
            body={`${commentNotification.title}: "${message.body}"`}
          />
        ),
        analytics: commentNotification.analytics,
      };

    case "notification:reply":
    case "notification:replyMention":
      const replyNotification = getReplyNotification(notification, groupName);
      return {
        text: (
          <GenericNotification
            body={`${replyNotification.title}: "${message.body}"`}
          />
        ),
        analytics: replyNotification.analytics,
      };

    case "notification:event":
      return {
        text: (
          <GenericNotification {...message} buttonLabel="See Event" title="" />
        ),
        analytics: {
          label: groupName
            ? "New Event in a Group"
            : "New Event in the Community",
          pendoClass: groupName
            ? "pendo_web-notifications-mygroup-newevent"
            : "pendo_web-notifications-community-newevent",
        },
      };

    case "notification:follow":
      return {
        text: <GenericNotification {...message} />,
        analytics: {
          category: "My Profile Activity",
          label: "Profile Followed",
          pendoClass: "pendo_web-notifications-profile-follow",
        },
      };

    case "notification:article":
      return {
        text: <GenericNotification body={message.body} />,
        analytics: {
          category: "My following activity",
          label: "Article from Following",
          pendoClass: "pendo_web-notifications-someoneifollow-article",
        },
      };

    case "notification:expertpanel":
      return {
        text: (
          <GenericNotification
            body={
              groupName ? message.body : aggregatedMessageTitle(notification)
            }
          />
        ),
        analytics: {
          label: groupName
            ? "New Group Expert Panel Roundup"
            : "New Expert Panel Roundup",
          pendoClass: groupName
            ? "pendo_web-notifications-mygroup-eproundup"
            : "pendo_web-notifications-community-eproundup",
        },
      };

    case "notification:answer_expertpanel":
      return {
        text: <GenericNotification body={message.body} />,
        analytics: {
          label: "New Expert Panels",
          pendoClass: "pendo_web-notifications-community-epquestion",
        },
      };

    case "notification:answer_group_expertpanel":
      return {
        text: <GenericNotification body={message.body} />,
        analytics: {
          label: "New Group Expert Panel",
          pendoClass: "pendo_web-notifications-mygroup-epquestion",
        },
      };

    case "notification:groupInvite":
      return {
        text: <GenericNotification {...message} buttonLabel="View Group" />,
        analytics: {
          category: "Group activity",
          label: "Member invited to join group",
          pendoClass: "pendo_web-notifications-group-invite",
        },
      };

    case "notification:groupInviteAccept":
      return {
        text: <GenericNotification {...message} />,
        analytics: {
          category: "Group activity",
          label: "Member response to group invite",
          pendoClass:
            "pendo_web-notifications-group-joinrequest-acceptmlrequest",
        },
      };

    case "notification:groupInviteReject":
      return {
        text: <GenericNotification {...message} />,
        analytics: {
          category: "Group activity",
          label: "Member response to group invite",
          pendoClass:
            "pendo_web-notifications-group-joinrequest-declinemlrequest",
        },
      };

    case "notification:groupJoinRequest":
      return {
        text: (
          <GenericNotification
            body={message.body}
            buttonLabel="Review request"
          />
        ),
        analytics: {
          category: "Group activity",
          label: "Member requests to join group",
          pendoClass: "pendo_web-notifications-group-joinrequest",
        },
      };

    case "notification:groupJoinRequestApprove":
      return {
        text: (
          <GenericNotification body={message.body} buttonLabel="View Group" />
        ),
        analytics: {
          category: "Group activity",
          label: "Member approved into group",
          pendoClass: "pendo_web-notifications-group-joinrequest-accepted",
        },
      };

    case "notification:groupJoinRequestReject":
      return {
        text: <GenericNotification body={message.body} />,
        analytics: {
          category: "Group activity",
          label: "Member join group request rejected",
          pendoClass: "pendo_web-notifications-group-joinrequest-declined",
        },
      };

    case "notification:pollOpened":
      return {
        text: (
          <GenericNotification
            body={message.body}
            buttonClass={
              groupName
                ? "pendo_button-notifications-gotopoll-group"
                : "pendo_button-notifications-gotopoll"
            }
            buttonLabel="Go to poll"
          />
        ),
        analytics: {
          pendoClass: groupName
            ? "pendo_web-notifications-newpoll-group"
            : "pendo_web-notifications-newpoll-general",
          label: groupName
            ? "New Poll in a Group"
            : "New Poll in the Community",
        },
      };

    case "notification:pollClosedAuthor":
      return {
        text: <GenericNotification body={message.body} />,
        analytics: {
          label: groupName
            ? "Closed Poll in a Group - Poll Creator"
            : "Closed Poll - Community - Poll Creator",
          pendoClass: groupName
            ? "pendo_web-notifications-closedpoll-group-creator"
            : "pendo_web-notifications-closedpoll-general-creator",
        },
      };

    case "notification:pollClosedVoter":
      return {
        text: <GenericNotification body={message.body} />,
        analytics: {
          label: groupName
            ? "Closed Poll in a Group - Participating Member"
            : "Closed Poll in the Community - Participating Member",
          pendoClass: groupName
            ? "pendo_web-notifications-closedpoll-group-participant"
            : "pendo_web-notifications-closedpoll-general-participant",
        },
      };

    case "view":
      return {
        text: (
          <GenericNotification
            body={`**${actor?.data?.name}** viewed your **profile**`}
          />
        ),
        analytics: {
          category: "My Profile activity",
          label: "Profile Viewed",
          pendoClass: "pendo_web-notifications-profile-view",
        },
      };

    case "like":
    case "celebrate":
    case "insightful":
    case "concerned":
      const { body, analytics } = getReactionNotification(notification);

      return {
        text: <GenericNotification body={body} />,
        analytics,
      };

    default:
      return { text: null };
  }
}
