import { useChannelDMs, useInitializedState } from "@communityco/chat-state";
import Head from "next/head";
import { useEffect, useState } from "react";

import { useApp } from "lib/common/appProvider";

function FavIcon() {
  const { channels } = useChannelDMs();
  const { isInitialized: isChatInitialized } = useInitializedState();
  const site = useApp("site");
  const [hasNewMessagesIndicator, setHasNewMessagesIndicator] = useState(false);

  const hasNewMessages = channels?.some((channel) => channel.unreadCount > 0);

  // change favIcon when it's out of the tab and user received a new message while there was no unread messages previously.
  useEffect(() => {
    if (
      !hasNewMessagesIndicator &&
      hasNewMessages &&
      document.visibilityState === "hidden"
    ) {
      setHasNewMessagesIndicator(true);
    }
  }, [channels, hasNewMessages, hasNewMessagesIndicator]);

  // whenever user is in or out of the tab, it will change the favIcon based on the unread messages.
  useEffect(() => {
    const checkHasNewMessages = () => {
      if (hasNewMessages && document.visibilityState === "hidden") {
        setHasNewMessagesIndicator(true);
      } else {
        setHasNewMessagesIndicator(false);
      }
    };

    document.addEventListener("visibilitychange", checkHasNewMessages);

    return () => {
      document.removeEventListener("visibilitychange", checkHasNewMessages);
    };
  }, [hasNewMessages]);

  if (isChatInitialized && hasNewMessagesIndicator) {
    return (
      <Head key="unread-indicator-head">
        <link href={site?.unreadFavIcon} rel="icon" type="image/png" />
      </Head>
    );
  }

  return (
    <Head key="read-indicator-head">
      <link href={site?.favIcon} rel="icon" type="image/png" />
    </Head>
  );
}

export default FavIcon;
