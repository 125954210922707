const ZENDESK_SCRIPT_ID = "ze-snippet";

export const initializeZendeskChat = ({
  apiKey,
  name,
  email,
  communityCode,
  brandCode,
  hide = false, // this is for landing page that should not have chat widget
}) => {
  let initScript = document.getElementById(ZENDESK_SCRIPT_ID);
  const safeName = name.replace(/'/g, "\\'");
  const safeEmail = email.replace(/'/g, "\\'");
  const widgetName = communityCode === "yec" ? "messenger" : "webWidget";
  if (apiKey && !initScript) {
    initScript = document.createElement("script");
    initScript.async = true;
    initScript.setAttribute("id", ZENDESK_SCRIPT_ID);
    initScript.setAttribute(
      "src",
      `https://static.zdassets.com/ekr/snippet.js?key=${apiKey}`
    );
    initScript.addEventListener("load", () => {
      const configScript = document.createElement("script");
      configScript.async = true;
      configScript.innerHTML = `
      window.zESettings = {
        ${widgetName}: {
          launcher: {
            chatLabel: {
              '*': ' '
            }
          },
          zIndex: 1000
        }
      };
      zE(function () {
        zE('webWidget', 'identify', {
          name: '${safeName}',
          email: '${safeEmail}'
        });

        zE('webWidget', 'prefill', {
          name: { 
              value: '${safeName}',
              readOnly: false
          },
          email: { 
              value: '${safeEmail}',
              readOnly: false
          },
        });
        if ('${brandCode}' !== '${communityCode}') {
          zE('webWidget', 'chat:addTags', ['${communityCode.toUpperCase()}']);
        }
        setTimeout(() => {
          zE("${widgetName}", "open");
          zE("${widgetName}", "close");
          if (${hide}) {
            zE("${widgetName}", "hide");
          }
        }, 300);
        $zopim(function() {
          $zopim.livechat.departments.filter('');
        });
        
      });`;
      document.body.appendChild(configScript);
    });
    document.body.appendChild(initScript);
  }
};

export const hideZendeskChat = (brandCode) => {
  if (brandCode === "yec") return window.zE && window.zE("messenger", "hide");
  return window.zE && window.zE("webWidget", "hide");
};
export const showZendeskChat = (brandCode) => {
  if (brandCode === "yec") return window.zE && window.zE("messenger", "show");
  return window.zE && window.zE("webWidget", "show");
};
