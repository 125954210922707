import {
  addDays,
  isAfter,
  isBefore,
  isWithinInterval,
  subDays,
} from "date-fns";

export const getEPAccentImageURL = (id: string): string => {
  if (!id) return "";
  const number =
    (Array.from(id.replace(/-/g, "")).reduce(
      (sum, char) => sum + parseInt(char, 16),
      0
    ) %
      19) +
    1;
  const formattedNumber = number.toString().padStart(2, "0");
  return `https://storage.googleapis.com/cco-web-assets/eps/abstract-images/ep-${formattedNumber}.png`;
};

const wasReleasedMoreThanSevenDaysAgo = (question) =>
  isBefore(new Date(question.releaseAt), subDays(new Date(), 7));
const willEndMoreThanSevenDaysFromNow = (question) =>
  isAfter(new Date(question.endAt), addDays(new Date(), 7));

const isAnswered = (question) => question.hasAnswered;
export const isNew = (question) =>
  !question.isEnded &&
  isWithinInterval(new Date(question.releaseAt), {
    start: subDays(new Date(), 7),
    end: new Date(),
  });
const isSpotlight = (question) =>
  !question.isEnded &&
  question.totalAnswers < question.maxAnswers / 2 &&
  wasReleasedMoreThanSevenDaysAgo(question) &&
  willEndMoreThanSevenDaysFromNow(question);
const isTrending = (question) =>
  !question.isEnded &&
  question.totalAnswers > question.maxAnswers * 0.75 &&
  wasReleasedMoreThanSevenDaysAgo(question) &&
  willEndMoreThanSevenDaysFromNow(question);
const isClosingSoon = (question) =>
  !question.isEnded &&
  isWithinInterval(new Date(question.endAt), {
    start: new Date(),
    end: addDays(new Date(), 7),
  });

const statusTags = [
  {
    label: "Answered",
    filter: isAnswered,
  },
  {
    label: "New",
    filter: isNew,
  },
  {
    label: "Spotlight",
    filter: isSpotlight,
  },
  {
    label: "Trending",
    filter: isTrending,
  },
  {
    label: "Closing soon",
    filter: isClosingSoon,
  },
];

const sourceMapping = {
  graph: {
    endAt: (question) => question.endAt,
    hasAnswered: (question) => question.myAnswer.hasAnswered,
    isEnded: (question) => question.isEnded,
    maxAnswers: (question) => question.maxAnswers,
    releaseAt: (question) => question.releaseAt,
    totalAnswers: (question) => question.activityPreview.totalAnswers,
  },
  feed: {
    endAt: (question) => question.object.object.data.endAt,
    hasAnswered: (question) =>
      question.object.own_reactions?.community_expertpanel_answer || false,
    isEnded: (question) => question.object.object.data.isEnded,
    maxAnswers: (question) => question.object.object.data.maxAnswers,
    releaseAt: (question) => question.object.object.data.releaseAt,
    totalAnswers: (question) =>
      question.object.reaction_counts?.community_expertpanel_answer || 0,
  },
};

export const getStatusTag = ({ dataSource = "graph", question }) => {
  if (!question) return null;

  const sourceData = sourceMapping[dataSource];

  const data = {
    endAt: sourceData.endAt(question),
    hasAnswered: sourceData.hasAnswered(question),
    isEnded: sourceData.isEnded(question),
    maxAnswers: sourceData.maxAnswers(question),
    releaseAt: sourceData.releaseAt(question),
    totalAnswers: sourceData.totalAnswers(question),
  };

  const tags = statusTags
    .filter((tag) => tag.filter(data))
    .map((tag) => tag.label);

  return tags[0] || null;
};
