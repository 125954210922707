import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Button, Stack, TextField, Typography } from "@mui/material";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { object, string } from "yup";

import { CONTACT_CONCIERGE_MUTATION } from "graphql/concierge/mutations";
import useMe from "hooks/useMe";
import { useApp } from "lib/common/appProvider";
import cls from "lib/common/cls";
import textFieldRegister from "lib/common/textFieldRegister";

const schema = object().shape({
  from: string()
    .email("Must be a valid email address")
    .required("This is a required field"),
  subject: string().required(),
  body: string()
    .max(140, "Must have a maximum of 140 characters")
    .required("This is a required field"),
});

const formItemStyles = {
  gap: "8px",
};

const getPendoClass = (context) => {
  switch (context) {
    case "maintenance":
      return "pendo_web-maintenance-CTA";
    case "dunning":
      return "pendo_web-dunning-CTA";

    default:
      return "";
  }
};

type ContactConciergeFormProps = {
  body: string;
  context?: "dunning" | "maintenance";
  handleCancel?: () => void;
  handleRequested: () => void;
  subject: string;
  submitButtonText?: string;
};

export default function ContactConciergeForm({
  body,
  context,
  handleCancel,
  handleRequested,
  subject,
  submitButtonText = "Send request",
}: ContactConciergeFormProps) {
  const { concierge } = useApp("site");
  const { email } = useMe();

  const [contactConcierge, { loading: contactingConcierge }] = useMutation(
    CONTACT_CONCIERGE_MUTATION,
    {
      onCompleted: () => {
        handleRequested();
      },
    }
  );

  const defaultValues = {
    from: email,
    subject,
    body,
  };

  const {
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    watch,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setValue("from", email);
  }, [email, setValue]);

  const characters = watch("body")?.length || 0;

  const handleSendRequest = (data) => {
    contactConcierge({
      variables: {
        input: data,
      },
    });
  };

  return (
    <Stack gap="16px">
      <Stack sx={formItemStyles}>
        <Typography variant="body2">To</Typography>
        <TextField
          fullWidth
          InputProps={{
            readOnly: true,
          }}
          placeholder={concierge?.email}
          size="small"
        />
      </Stack>

      <Stack sx={formItemStyles}>
        <Typography variant="body2">
          From <span style={{ color: "#D11717" }}>*</span>
        </Typography>
        <TextField
          fullWidth
          error={!!errors.from}
          helperText={errors.from ? errors.from.message : ""}
          rows={2}
          size="small"
          type="email"
          {...textFieldRegister({ name: "from", register })}
        />
      </Stack>

      <Stack sx={formItemStyles}>
        <Typography variant="body2">Subject</Typography>
        <TextField
          fullWidth
          InputProps={{
            readOnly: true,
          }}
          placeholder={defaultValues.subject}
          size="small"
        />
      </Stack>

      <Stack sx={formItemStyles}>
        <Typography variant="body2">
          Email body <span style={{ color: "#D11717" }}>*</span>
        </Typography>
        <TextField
          fullWidth
          multiline
          error={!!errors.body}
          helperText={errors.body ? errors.body.message : ""}
          rows={2}
          size="small"
          {...textFieldRegister({ name: "body", register })}
        />
        <Typography
          component="div"
          sx={{ textAlign: "right" }}
          variant="caption"
        >
          {characters || 0}/140
        </Typography>
      </Stack>

      <Stack direction="row" gap={1} justifyContent="end">
        {handleCancel && <Button onClick={handleCancel}>Cancel</Button>}
        <LoadingButton
          className={cls([getPendoClass(context)])}
          loading={contactingConcierge}
          variant="contained"
          onClick={handleSubmit(handleSendRequest)}
        >
          {submitButtonText}
        </LoadingButton>
      </Stack>
    </Stack>
  );
}
