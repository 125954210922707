/* eslint-disable no-param-reassign */
function mdastTypeToRawText(node, appendStr, isLongEnough = false) {
  if (node.value && node.type !== "delete") {
    isLongEnough = appendStr(node.value);
  } else if (node.type === "thematicBreak") {
    isLongEnough = appendStr(" -- ");
  }

  if (Array.isArray(node.children)) {
    node.children.some((child) =>
      mdastTypeToRawText(child, appendStr, isLongEnough)
    );
  }

  return isLongEnough;
}

export default function mdastRawText(rootNode, maxLength = Infinity) {
  const values = [];
  let totalLen = 0;

  function appendStr(str) {
    values.push(str);
    totalLen += str.length;
    return totalLen >= maxLength;
  }

  mdastTypeToRawText(rootNode, appendStr);
  return values.join("");
}
