import { Roles } from "types/User";

export default function getRole(roles): Roles {
  let role: Roles = "PUBLIC";
  if (roles.includes("ADMIN")) {
    role = "ADMIN";
  } else if (roles.includes("MEMBER")) {
    role = "MEMBER";
  }
  return role;
}
