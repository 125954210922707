import { useCallback, useEffect, useRef, useState } from "react";

import { useApp } from "lib/common/appProvider";
import baseUrl from "lib/site/baseUrl";

const notificationsFilter = ({ meId, notifications }) => {
  return notifications.reduce((acc, notification) => {
    if (notification.verb === "join") return acc;

    if (notification.verb === "notification:pollClosedAuthor") {
      acc.push(notification);
      return acc;
    }

    const filteredActivities = notification?.activities?.filter(
      (activity) => activity?.actor?.id !== meId
    );
    notification.activities = filteredActivities;
    notification.activity_count = filteredActivities.length;
    if (notification.activity_count === 0) return acc; // If no activies, remove notification

    const uniqueActors = [
      ...new Set(
        notification.activities.map((activity) => activity?.actor?.id)
      ),
    ].length;
    notification.actor_count = uniqueActors;
    acc.push(notification);
    return acc;
  }, []);
};

export default function useNotifications() {
  const { user } = useApp("auth");
  const feedClient = useApp("feedClient");
  const [notifications, setNotifications] = useState(null);
  const [unseenCount, setUnseenCount] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingNextItems, setLoadingNextItems] = useState(false);
  const [failTimeout, setFailTimeout] = useState(null);
  const [hasNext, setHasNext] = useState(true);
  const [lastId, setLastId] = useState(null);

  const recentNotificationsIds = useRef(new Set());
  const hasFetchedInitialNotifications = useRef(false);

  const limit = 100;

  const streamFeedToken = user?.externalTokens?.getStreamFeed;
  const meId = user?.id;

  const notificationsFeed = feedClient?.feed("notification", meId);
  const notificationsFeedRealTime = feedClient?.feed(
    "notification",
    meId,
    streamFeedToken
  );

  const handleGetFail = async ({
    mark_read = false,
    mark_seen = false,
  } = {}) => {
    const { data: notificationsData } = await fetch(
      baseUrl(
        // eslint-disable-next-line camelcase
        `/api/notifications?mark_read=${mark_read}&mark_seen=${mark_seen}`
      )
    ).then((res) => res.json());
    return notificationsData;
  };

  const getNotifications = useCallback(
    async ({ id_lt = null } = { id_lt: null }) => {
      if (failTimeout) {
        clearTimeout(failTimeout);
        setFailTimeout(null);
      }

      return notificationsFeed.get({ id_lt, limit }).catch(() => {
        // Retry/get notifications every minute
        setFailTimeout(
          setTimeout(() => {
            // eslint-disable-next-line no-use-before-define
            fetchNotifications();
          }, 60000)
        );

        // If notifications feed inaccessible through stream client, try through REST instead
        return handleGetFail().catch(() => {
          setError(true);
        });
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [notificationsFeed]
  );

  const markAllAsRead = useCallback(
    async () => {
      setNotifications((prevState) => {
        return prevState.map((notification) => {
          const updatedNotification = JSON.parse(JSON.stringify(notification));
          updatedNotification.is_read = true;
          return updatedNotification;
        });
      });

      notificationsFeed
        .get({
          limit,
          mark_read: true,
        })
        .catch((err) => {
          handleGetFail({ mark_read: true });
          console.error({ err });
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [notificationsFeed]
  );

  const markAllAsSeen = useCallback(
    async () => {
      setUnseenCount(0);
      notificationsFeed
        .get({
          limit,
          mark_seen: true,
        })
        .catch((err) => {
          handleGetFail({ mark_seen: true });
          console.error({ err });
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [notificationsFeed]
  );

  const readNotification = useCallback(
    async ({ notificationId } = {}) => {
      setNotifications((prevState) => {
        return prevState.map((notification) => {
          if (notification.id === notificationId) {
            const updatedNotification = JSON.parse(
              JSON.stringify(notification)
            );
            updatedNotification.is_read = true;
            return updatedNotification;
          }
          return notification;
        });
      });
      return notificationsFeed
        .get({ limit, mark_read: notificationId })
        .catch((err) => {
          handleGetFail({ mark_read: notificationId });
          console.error({ err });
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [notificationsFeed]
  );

  const fetchNotifications = useCallback(() => {
    setError(false);
    setLoading(true);
    getNotifications().then((res) => {
      setLoading(false);

      if (res) {
        if (res?.results?.length < limit) {
          setHasNext(false);
        }

        if (res?.results?.length > 0) {
          setLastId(res?.results?.[(res?.results?.length || 0) - 1]?.id);
        }

        // Filter out notifications in which the current user is the only actor
        // and remove current user activites from the notification activities array
        // and filter out "join" notifications
        const filteredResults = notificationsFilter({
          meId,
          notifications: res.results,
        });

        setNotifications(filteredResults);
        setUnseenCount(res.unseen);
      }
    });
  }, [getNotifications, meId]);

  const getNext = useCallback(() => {
    setLoadingNextItems(true);
    getNotifications({ id_lt: lastId }).then((res) => {
      setLoadingNextItems(false);

      if (res) {
        if (res?.results?.length < limit) {
          setHasNext(false);
        }

        if (res?.results?.length > 0) {
          setLastId(res?.results?.[(res?.results?.length || 0) - 1]?.id);
        }

        // Filter out notifications in which the current user is the only actor
        // and remove current user activites from the notification activities array
        // and filter out "join" notifications
        const filteredResults = notificationsFilter({
          meId,
          notifications: res.results,
        });

        setNotifications(notifications.concat(filteredResults));
        setUnseenCount(res.unseen);
      }
    });
  }, [getNotifications, lastId, meId, notifications]);

  const watchCallback = useCallback(
    (data) => {
      const newNotificationId = data?.new?.[0]?.id;
      if (!recentNotificationsIds.current.has(newNotificationId)) {
        recentNotificationsIds.current.add(newNotificationId);
        fetchNotifications();
      }
    },
    [recentNotificationsIds, fetchNotifications]
  );

  notificationsFeedRealTime.subscribe(watchCallback);

  useEffect(() => {
    if (!notifications && !hasFetchedInitialNotifications.current) {
      fetchNotifications();
      hasFetchedInitialNotifications.current = true;
    }
  }, [fetchNotifications, hasFetchedInitialNotifications, notifications]);

  return {
    error,
    fetchNotifications,
    getNext,
    getNotifications,
    hasNext,
    loading: !hasFetchedInitialNotifications.current && loading,
    loadingNextItems,
    markAllAsRead,
    markAllAsSeen,
    notifications,
    readNotification,
    unseenCount,
  };
}
