import {
  DateRange,
  FormatQuote,
  InsertChartOutlined,
} from "@mui/icons-material";

import { styled } from "@mui/material";

import Avatar from "components/shared/Avatar";

import { Verb } from "types/activity";

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  marginRight: "8px",
  backgroundColor: theme.palette.grey[100],
}));

type Props = {
  avatar: string;
  name: string;
  verb: Verb;
};

export default function NotificationAvatar({ avatar, name, verb }: Props) {
  switch (verb) {
    case "expertpanel":
    case "answer_expertpanel":
    case "group_expertpanel":
    case "notification:expertpanel":
    case "notification:answer_expertpanel":
    case "notification:answer_group_expertpanel":
      return (
        <StyledAvatar>
          <FormatQuote sx={{ color: "primary.dark" }} />
        </StyledAvatar>
      );

    case "event":
    case "notification:event":
      return (
        <StyledAvatar>
          <DateRange sx={{ color: "primary.dark" }} />
        </StyledAvatar>
      );

    case "notification:pollClosedVoter":
      return (
        <StyledAvatar>
          <InsertChartOutlined sx={{ color: "primary.dark" }} />
        </StyledAvatar>
      );

    default:
      return <StyledAvatar alt={name} src={avatar} />;
  }
}
