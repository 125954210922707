import {
  Alert,
  AlertTitle,
  Button,
  Snackbar as MuiSnackbar,
} from "@mui/material";
// leave it as a separate import for now, somehow it's causing an import issue when imported together.
import Portal from "@mui/material/Portal";
import { useImperativeHandle, useState } from "react";

/*
    # HOW TO USE
    1 - import the Snackbar component on the page
    2 - import the useRef from react.
    3 - create a reference for the snackbar
    4 - use the Snackbar passing the reference: <Snackbar reference={snackbarRef} />
    4.1 - if the snackbar should be displayed inside a modal, pass a `isWithinModal` prop to it: <Snackbar reference={snackbarRef} />
    5 - to call the snackbar simply use the reference to call the method:
    snackbarRef?.current?.callSnackbar(
        "Your error message goes here",
        "error" // here you pass the snackbar style: error || success || info || warning
    );
*/

export default function Snackbar({
  actionText = null,
  duration = 6000,
  elevation = 10,
  isWithinModal = false,
  reference,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState(null);
  const [message, setMessage] = useState("");
  const [type, setType] = useState("error");

  const types = ["error", "info", "success", "warning"];

  const callSnackbar = (snackMessage, snackType, snackTitle = null) => {
    setTitle(snackTitle);
    setMessage(snackMessage);
    setIsOpen(true);
    setType(types.includes(snackType) ? snackType : "error");
  };

  useImperativeHandle(reference, () => ({
    callSnackbar,
  }));

  return (
    <Portal disablePortal={isWithinModal}>
      <MuiSnackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        autoHideDuration={duration}
        open={isOpen}
        sx={{ width: isWithinModal ? "90%" : "auto", position: "absolute" }}
        onClose={() => setIsOpen(false)}
      >
        <Alert
          action={
            actionText ? (
              <Button onClick={() => setIsOpen(false)}>{actionText}</Button>
            ) : null
          }
          elevation={elevation}
          severity={type}
          sx={{
            "& .MuiAlert-action": {
              paddingTop: "0",
              alignItems: "center",
            },
          }}
          variant="standard"
          onClose={() => setIsOpen(false)}
        >
          {title && <AlertTitle>{title}</AlertTitle>}
          {message}
        </Alert>
      </MuiSnackbar>
    </Portal>
  );
}
