/**
 * Utility to help construct css className strings
 * Input: ["classOne", "", undefined, {classTwo: true}, {classThree: false}]
 * Output: "classOne classTwo"
 */
export default function cls(
  classesArray: (Record<string, boolean> | string | undefined)[]
): string {
  return classesArray
    .reduce((acc: string[], item) => {
      if (typeof item === "object") {
        const keys = Object.keys(item);
        if (item[keys[0]]) {
          acc.push(keys[0]);
        }
      }

      if (typeof item === "string" && item !== "") {
        acc.push(item);
      }

      return acc;
    }, [])
    .join(" ");
}
