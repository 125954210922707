import { set } from "lodash";

const PATH = "Twilio.FlexWebChat";
const STATE_PATH = "twilioState";

export function isDuringAvailableHours(currentDate = new Date()) {
  const utc = currentDate.getTime() + currentDate.getTimezoneOffset() * 60000;
  const estDate = new Date(utc + 3600000 * -5);

  const openTimeInMins = 9 * 60 + 30;
  const closedTimeInMins = 17 * 60 + 30;

  const mins = estDate.getHours() * 60 + estDate.getMinutes();
  const dayOfWeek = estDate.getDay();

  return (
    dayOfWeek >= 1 &&
    dayOfWeek <= 5 &&
    mins > openTimeInMins &&
    mins < closedTimeInMins
  );
}

function configureOffHours() {
  const duringAvailableHours = isDuringAvailableHours();
  set(
    window,
    `${PATH}.MessagingCanvas.defaultProps.predefinedMessage.body`,
    duringAvailableHours
      ? "How can we help you today?"
      : "Sorry, we aren’t online at the moment.  Send a message and we will respond via email during our normal business hours of 9:30 - 5:30 EST"
  );
}

const FLEX_CHAT_SCRIPT_ID = "flex-chat-script";

export function initializeFlexChat({
  uid,
  email,
  name,
  brandCode,
  communityCode,
  accountSid,
  flexFlowSid,
  conciergeEmail,
  hide = false, // this is for landing page that should not have chat widget
}) {
  // Disable flex chat on some pages. This will only be run on full page loads.
  let initScript = document.getElementById(FLEX_CHAT_SCRIPT_ID);
  if (initScript) return;
  initScript = document.createElement("script");
  initScript.async = true;
  initScript.setAttribute("id", FLEX_CHAT_SCRIPT_ID);
  initScript.setAttribute(
    "src",
    "https://assets.flex.twilio.com/releases/flex-webchat-ui/2.5.0/twilio-flex-webchat.min.js"
  );
  initScript.addEventListener("load", () => {
    const config = {
      logLevel: "warn",
      accountSid,
      flexFlowSid,
      context: {
        friendlyName: name,
        email,
        id: uid,
        brandCode,
        communityCode,
        conciergeEmail,
      },
      markdownSupport: {
        enabled: true,
      },
      fileAttachment: {
        enabled: isDuringAvailableHours(),
        acceptedExtensions: [
          "jpg",
          "jpeg",
          "jpe",
          "jif",
          "jfif",
          "jfi",
          "png",
          "gif",
          "webp",
          "tiff",
          "tif",
          "raw",
          "bmp",
          "heif",
          "heic",
          "svg",
          "svgz",
        ],
      },
      componentProps: {
        EntryPoint: {
          tagline: "",
        },
      },
      colorTheme: {
        overrides: {
          EntryPoint: {
            Container: {
              background: "#333333",
              "&:hover": {
                background: "#333333",
              },
            },
          },
          Chat: {
            MessageListItem: {
              FromMe: {
                Bubble: {
                  background: "#333333",
                },
              },
              FromOthers: {
                Bubble: {
                  background: "#f4f4f4",
                },
              },
            },
            MessageInput: {
              Container: {
                background: "#f4f4f4",
              },
              Button: {
                background: "#333333",
              },
              AttachFileButton: {
                background: "#ffffff",
              },
            },
            MessageCanvasTray: {
              Container: {
                background: "#f4f4f4",
              },
              Button: {
                background: "#333333",
              },
            },
          },
          Progress: {
            Circular: {
              staticBackgroundBorderColor: "#f4f4f4",
              animatingBackgroundBorderColor: "#f4f4f4",
              animatingForegroundBorderColor: "#333333",
            },
          },
        },
      },
    };

    set(
      window,
      `${PATH}.MessagingCanvas.defaultProps.predefinedMessage.authorName`,
      "Concierge"
    );
    set(
      window,
      `${PATH}.MessagingCanvas.defaultProps.showWelcomeMessage`,
      false
    );

    configureOffHours();

    window.Twilio.FlexWebChat.Actions.on("beforeToggleChatVisibility", () => {
      configureOffHours();
    });

    window.Twilio.FlexWebChat.Actions.on("afterMinimizeChat", async () => {
      const { channelSid } =
        window[STATE_PATH].manager.store.getState().flex.session;
      fetch(
        `https://twilio-serverless-8275-dev.twil.io/end-chat?channelSid=${channelSid}`
      );
    });

    // Check if twilio state has already been added to the window object to prevent
    // it breaking when the app hot reloads during dev
    if (!window[STATE_PATH]) {
      window.Twilio.FlexWebChat.renderWebChat(config).then((twilioState) => {
        window[STATE_PATH] = twilioState;
        if (hide && window.document.querySelector(".Twilio-EntryPoint")) {
          window.document.querySelector(".Twilio-EntryPoint").style.display =
            "none";
        }
      });
    }
  });
  document.body.appendChild(initScript);
}

export const hideFlexChat = () => {
  if (window.document.querySelector(".Twilio-EntryPoint")) {
    window.document.querySelector(".Twilio-EntryPoint").style.display = "none";
  }
};

export const showFlexChat = () => {
  if (window.document.querySelector(".Twilio-EntryPoint")) {
    window.document.querySelector(".Twilio-EntryPoint").style.display = "block";
  }
};
