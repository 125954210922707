import pluralize from "lib/common/pluralize";

const formatDate = (date, context) => {
  const dateObj = date ? new Date(date) : new Date();

  // Check if is valid date
  if (!(dateObj instanceof Date) || Number.isNaN(dateObj)) return null;

  const today = new Date();
  const localeDate = new Date(dateObj.toLocaleDateString());
  const localeToday = new Date(today.toLocaleDateString());
  const elapsedTime = localeDate.getTime() - localeToday.getTime();
  const elapsedDays = Math.ceil(elapsedTime / (1000 * 3600 * 24));

  const offsetDateObj = date ? new Date(date) : new Date();
  offsetDateObj.setMinutes(
    dateObj.getMinutes() - offsetDateObj.getTimezoneOffset()
  );

  const DAY_IN_MS = 86400000; // 24 * 60 * 60 * 1000
  const yesterday = new Date(today - DAY_IN_MS);
  const lastWeek = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - 7
  );
  const seconds = Math.round((today - offsetDateObj) / 1000);
  const minutes = Math.round(seconds / 60);
  const hours = Math.round(minutes / 60);
  const isToday = today.toDateString() === offsetDateObj.toDateString();
  const isYesterday = yesterday.toDateString() === offsetDateObj.toDateString();
  const isWithinPastWeek =
    new Date(
      offsetDateObj.getFullYear(),
      offsetDateObj.getMonth(),
      offsetDateObj.getDate() - 7
    ) > lastWeek;
  const isThisYear = today.getFullYear() === offsetDateObj.getFullYear();

  switch (context) {
    case "chat":
      // If today
      if (elapsedDays === 0) {
        return "Today";
      }

      // If yesterday
      if (elapsedDays === -1) return "Yesterday";

      // If this year
      if (isThisYear) {
        return new Intl.DateTimeFormat("en-US", {
          weekday: "long",
          month: "long",
          day: "numeric",
        }).format(dateObj);
      }

      // If prior year
      return new Intl.DateTimeFormat("en-US", {
        weekday: "long",
        month: "long",
        day: "numeric",
        year: "numeric",
      }).format(dateObj);

    case "article":
      return new Intl.DateTimeFormat("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      }).format(dateObj);

    case "post":
      // If today
      if (elapsedDays === 0) {
        return new Intl.DateTimeFormat("en-US", {
          hour: "numeric",
          minute: "numeric",
        }).format(dateObj);
      }

      // If this year
      if (isThisYear) {
        return `${new Intl.DateTimeFormat("en-US", {
          month: "long",
          day: "numeric",
        }).format(dateObj)} at ${new Intl.DateTimeFormat("en-US", {
          hour: "numeric",
          minute: "numeric",
        }).format(dateObj)}`;
      }

      // If prior year
      return `${new Intl.DateTimeFormat("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      }).format(dateObj)} at ${new Intl.DateTimeFormat("en-US", {
        hour: "numeric",
        minute: "numeric",
      }).format(dateObj)}`;

    case "comment":
      // If this year
      if (isThisYear) {
        return `${new Intl.DateTimeFormat("en-US", {
          month: "long",
          day: "numeric",
        }).format(dateObj)} at ${new Intl.DateTimeFormat("en-US", {
          hour: "numeric",
          minute: "numeric",
        }).format(dateObj)}`;
      }

      // If prior year
      return `${new Intl.DateTimeFormat("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      }).format(dateObj)} at ${new Intl.DateTimeFormat("en-US", {
        hour: "numeric",
        minute: "numeric",
      }).format(dateObj)}`;

    case "notification":
      if (seconds < 5) {
        return "now";
      }
      if (seconds < 60) {
        return `${seconds} seconds ago`;
      }
      if (seconds < 90) {
        return "about a minute ago";
      }
      if (minutes < 60) {
        return `${minutes} ${pluralize("minute", minutes)} ago`;
      }
      if (isToday) {
        return `${hours} ${pluralize("hour", hours)} ago`;
      }
      if (isYesterday) {
        return "Yesterday";
      }
      if (isWithinPastWeek) {
        return new Intl.DateTimeFormat("en-US", {
          weekday: "short",
        }).format(dateObj);
      }
      if (isThisYear) {
        return new Intl.DateTimeFormat("en-US", {
          month: "short",
          day: "numeric",
        }).format(dateObj);
      }
      return new Intl.DateTimeFormat("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      }).format(dateObj);

    case "event": {
      const options = {
        month: "short",
        day: "numeric",
        year: "numeric",
        weekday: "short",
      };
      return new Intl.DateTimeFormat("en-US", options).format(dateObj);
    }

    default:
      // If today
      if (elapsedDays === 0) {
        return new Intl.DateTimeFormat("en-US", {
          hour: "numeric",
          minute: "numeric",
        }).format(dateObj);
      }

      // If yesterday
      if (elapsedDays === -1) return "Yesterday";

      // If within the past week
      if (elapsedDays > -7) {
        return new Intl.DateTimeFormat("en-US", { weekday: "long" }).format(
          dateObj
        );
      }

      // If this year
      if (isThisYear) {
        return new Intl.DateTimeFormat("en-US", {
          month: "short",
          day: "numeric",
        }).format(dateObj);
      }

      // If prior year
      return new Intl.DateTimeFormat("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
      }).format(dateObj);
  }
};

export default formatDate;
