import { gql } from "@apollo/client";

import { FEED_GROUP_BASIC_FRAGMENT } from "graphql/groups/fragments";

export const ME_ONBOARDING_FRAGMENT = gql`
  fragment MeOnboarding_Fragment on Me {
    id
    name {
      first
      fullName
    }
    email
    profile {
      avatar
      bio
      jobTitle
      company {
        name
        industry
      }
      location {
        code
        name
        familiarName
        placeId
      }
      pronouns
      displayPronouns
    }
    convoyStanding
  }
`;

export const ME_FULL_FRAGMENT = gql`
  fragment MeFull_Fragment on Me {
    id
    joinedAt
    membershipTier
    name {
      first
      fullName
      last
    }
    email
    profile {
      avatar
      bio
      website
      jobTitle
      company {
        name
        description
        industry
        employeeCount
        website
        social {
          facebook
          twitter
          linkedIn
          instagram
        }
        areasOfFocus
      }
      social {
        facebook
        twitter
        linkedIn
        instagram
      }
      location {
        code
        name
        familiarName
        placeId
      }
      skills
      displayPronouns
      pronouns
      publicUrl
    }
    convoyStanding
    myGroups {
      groups {
        ...FeedGroupBasic_Fragment
      }
      groupsILead {
        groupId
        role
        capabilities
      }
    }
    externalTokens {
      getStreamFeed
      getStreamChat
    }
    engagement {
      hasMobileApp
      lastAccessed {
        anyDevice
      }
      products {
        articles {
          lastEngagedAt
          lastPublishedAt
        }
        expertPanels {
          lastAnsweredAt
          lastPublishedAt
        }
      }
    }
  }
  ${FEED_GROUP_BASIC_FRAGMENT}
`;
