import { Close, DoneAll, MoreHoriz } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import Link from "next/link";
import { useState } from "react";

import NotificationMenuItemContainer from "components/notifications/NotificationMenuItemContainer";
import { useApp } from "lib/common/appProvider";

export default function NotificationsDropdown({
  anchorRef,
  error,
  handleClose,
  loading,
  markAllAsRead,
  notifications,
  readNotification,
}) {
  const { user } = useApp("auth");
  const [optionsMenuAnchorEl, setOptionsMenuAnchorEl] = useState(null);

  return (
    <Menu
      keepMounted
      anchorEl={anchorRef}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      id="notifications-menu"
      open={Boolean(anchorRef)}
      sx={(theme) => ({
        "& .MuiList-padding": {
          padding: 0,
        },
        "& .MuiMenu-paper": {
          width: "475px",
          maxHeight: "640px",

          [theme.breakpoints.down("sm")]: {
            width: "100vw",
            maxWidth: "100vw",
            left: "0 !important",
            maxHeight: "calc(var(--vh) - 72px)",
          },
        },
      })}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      onClose={handleClose}
    >
      {!error && !loading && (
        <Box pb={3}>
          <Box
            alignItems="center"
            display="flex"
            justifyContent="space-between"
            sx={(theme) => ({
              position: "sticky",
              top: 0,
              backgroundColor: theme.palette.background.paper,
              zIndex: 2,
              padding: "18px 16px 14px",
              borderBottom: "1px solid rgba(0,0,0,0.12)",

              [theme.breakpoints.down("sm")]: {
                flexDirection: "column",
                alignItems: "start",
              },
            })}
          >
            <Box
              alignItems="center"
              display="flex"
              flex="1"
              justifyContent="space-between"
              width="100%"
            >
              <Typography variant="h3">Notifications</Typography>
              <IconButton
                size="small"
                sx={{ display: { xs: "inline-flex", sm: "none" } }}
                onClick={handleClose}
              >
                <Close />
              </IconButton>
            </Box>

            <IconButton
              onClick={(e) => setOptionsMenuAnchorEl(e.currentTarget)}
            >
              <MoreHoriz />
            </IconButton>

            <Menu
              anchorEl={optionsMenuAnchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              open={Boolean(optionsMenuAnchorEl)}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              onClose={() => setOptionsMenuAnchorEl(null)}
            >
              <MenuItem
                className="pendo_web-notifications-markallasread"
                onClick={() => {
                  markAllAsRead();
                  setOptionsMenuAnchorEl(null);
                }}
              >
                <ListItemIcon>
                  <DoneAll />
                </ListItemIcon>
                <ListItemText>Mark all as read</ListItemText>
              </MenuItem>
            </Menu>
          </Box>

          <Link legacyBehavior passHref href="/notifications">
            <Button
              className="pendo_web-notifications-see-all"
              sx={{
                mt: "10px",
                mb: "12px",
                ml: "10px",
              }}
              variant="link"
              onClick={handleClose}
            >
              See all notifications
            </Button>
          </Link>

          {notifications?.map((notification) => {
            return (
              <NotificationMenuItemContainer
                key={notification.id}
                handleMenuClose={handleClose}
                notification={notification}
                readNotification={readNotification}
                user={user}
              />
            );
          })}
        </Box>
      )}

      {error && !loading && (
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          sx={{ p: "32px" }}
        >
          <Typography>Sorry, notifications seem to be offline.</Typography>
        </Box>
      )}

      {loading && (
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          sx={{ p: "32px" }}
        >
          <CircularProgress />
        </Box>
      )}
    </Menu>
  );
}
