import { ApolloClient, InMemoryCache } from "@apollo/client";

const globalClients = {};

export default function createClient(uri) {
  if (!globalClients[uri]) {
    globalClients[uri] = new ApolloClient({
      uri,
      cache: new InMemoryCache({
        typePolicies: {
          Query: {
            fields: {
              feedGroup: {
                read: (existingRef, { args, toReference }) => {
                  if (args.input.groupId) {
                    return toReference({
                      __typename: "FeedGroup",
                      id: args.input.groupId,
                    });
                  }

                  return existingRef;
                },
              },
            },
          },
        },
      }),
    });
    if (typeof window !== "undefined" && !uri.toLowerCase().includes("admin")) {
      window.__APOLLO_CLIENT__ = globalClients[uri];
    }
  }
  return globalClients[uri];
}
