import { useQuery } from "@apollo/client";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { useEffect, useState } from "react";

import { ME_QUERY } from "graphql/me/queries";
import useIdentity from "hooks/useIdentity";
import { Identity, Me } from "types/User";

type AuthUser = Me & { uid: string };

type Auth = {
  loading: boolean;
  ldIdentified: boolean;
  user: AuthUser | Identity;
  isLoggedIn: boolean;
};

export default function useAuth(): Auth {
  const ldClient = useLDClient();
  const jwtIdentity = useIdentity();
  const { loading: loadingMe, data } = useQuery(ME_QUERY);
  const [ldIdentified, setLdIdentified] = useState<boolean>(false);
  const [user, setUser] = useState<AuthUser | undefined>();

  const isLoggedIn = !!user;
  const { brandCode, communityCode, role } = jwtIdentity;

  useEffect(() => {
    if (!loadingMe && data) {
      setUser({ ...data.me, uid: data.me.id });
    }
  }, [data, loadingMe]);

  useEffect(() => {
    if (!ldIdentified && ldClient && isLoggedIn) {
      ldClient?.identify(
        {
          key: user.id,
          name: user.name.fullName,
          email: user.email,
          custom: {
            brandCode,
            communityCode,
            role,
          },
        },
        null,
        () => setLdIdentified(true)
      );
    }
  }, [
    brandCode,
    communityCode,
    isLoggedIn,
    ldClient,
    ldIdentified,
    user,
    role,
  ]);

  // loadingMe will intially be undefined if the user is not authenticated since the useQuery
  // hook will not be running. If the user is initially authenticated loadingMe will be a
  // boolean starting as true since the useQuery hook will be running.
  return {
    loading: loadingMe && !ldIdentified,
    ldIdentified,
    user: user || jwtIdentity,
    isLoggedIn,
  };
}
