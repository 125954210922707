const getTarget = (link) => {
  const url = new URL(link, window.location.origin);
  const target = url.host !== window.location.host ? "_blank" : undefined;

  return target;
};

export default function getNotificationLink({ notification, conciergeEmail }) {
  const { activities, verb } = notification;
  const { activityId, actor, groupRef, reaction, messageData } =
    activities?.[0] ?? {};

  const isAdmin = actor?.data?.type === "ADMIN";

  let link = "";
  const uriEncodedGroupSlug = encodeURIComponent(groupRef?.data?.slug);

  switch (verb) {
    case "notification:groupInvite":
    case "notification:groupJoinRequestApprove":
      link = `/groups/${uriEncodedGroupSlug}`;
      break;

    case "notification:groupInviteAccept":
    case "notification:groupInviteReject":
      link = `/groups/${uriEncodedGroupSlug}?tab=members&subTab=invites`;
      break;

    case "notification:groupJoinRequest":
      link = `/groups/${uriEncodedGroupSlug}?tab=members&subTab=requests`;
      break;

    case "notification:groupJoinRequestReject":
      link = isAdmin
        ? `mailto:${conciergeEmail}?subject=Declined Private Group Request`
        : "/groups";
      break;

    case "notification:answer_expertpanel":
      link = "/publishing/expert-panels";
      break;

    case "notification:answer_group_expertpanel":
      link = `/groups/${groupRef?.data?.slug}?tab=expert_panels`;
      break;

    case "view":
    case "notification:follow":
      link = `/profile/${actor?.id}`;
      break;

    case "like":
    case "celebrate":
    case "insightful":
    case "concerned":
      link = reaction?.data?.redirectUrl || `/activities/${activityId}`;
      break;

    case "notification:comment":
    case "notification:commentMention":
    case "notification:reply":
    case "notification:replyMention":
    case "notification:expertpanel":
      link = `/activities/${messageData?.activityId}`;
      break;

    default:
      link = messageData?.objectId
        ? `/activities/${messageData?.objectId}`
        : "";
  }

  return {
    link,
    target: getTarget(link),
  };
}

export function getLegacyNotificationLink({ notification }) {
  const { activities, verb } = notification;
  const { activityId, groupRef, id, object, reaction } = activities?.[0] ?? {};

  let link = "";

  switch (verb) {
    case "post":
    case "event":
      link = `/activities/${id}`;
      break;

    case "comment":
    case "reply":
      link = reaction?.data?.redirectUrl || `/activities/${activityId}`;
      break;

    case "mention": {
      const activityData = JSON.parse(object);
      link = activityData?.redirectUrl;
      break;
    }

    case "article":
      link = `/activities/${id}`;
      break;

    case "expertpanel":
      link = `/activities/${id}`;
      break;

    case "answer_expertpanel":
      link = "/publishing/expert-panels";
      break;

    case "group_expertpanel":
      link = `/groups/${groupRef?.data?.slug}?tab=expert_panels`;
      break;

    case "poll":
      link = `/activities/${id}`;
      break;

    default:
      link = "";
  }

  return {
    link,
    target: getTarget(link),
  };
}
