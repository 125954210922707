import { Box, Typography, styled } from "@mui/material";
import { ReactNode } from "react";

import Truncate from "components/shared/Truncate";
import formatDate from "lib/date/formatDate";
import { Verb } from "types/activity";

import NotificationAvatar from "./NotificationAvatar";

const StyledDot = styled("div")(({ theme }) => ({
  width: "8px",
  height: "8px",
  borderRadius: "50%",
  flexShrink: 0,
  backgroundColor: theme.palette.error.main,
  marginTop: "16px",
  marginLeft: "18px",
}));

type Props = {
  avatar: string;
  createdAt: string;
  isRead: boolean;
  link: string;
  name: string;
  onClick: (path: string) => void;
  pendoClass: string;
  text: ReactNode;
  verb: Verb;
};

export default function NotificationMenuItem({
  avatar,
  createdAt,
  isRead,
  link,
  name,
  onClick,
  pendoClass,
  text,
  verb,
}: Props) {
  return (
    <Box
      className={pendoClass}
      sx={(theme) => ({
        padding: "12px 16px",
        color: theme.palette.text.primary,
        textTransform: "none",
        justifyContent: "start",
        alignItems: "start",
        textAlign: "left",
        width: "100%",
        cursor: "pointer",
        display: "flex",
        "&:hover": {
          backgroundColor: theme.palette.action.hover,
        },
      })}
      onClick={() => onClick(link || "/")}
    >
      <NotificationAvatar avatar={avatar} name={name} verb={verb} />

      <Box
        display="flex"
        flexDirection="column"
        sx={{
          whiteSpace: "break-spaces",
          flexGrow: 1,
        }}
      >
        <Truncate maxLines={3}>
          <Typography component="span" variant="body2">
            {text}
          </Typography>
        </Truncate>

        <Typography color="textSecondary" variant="caption">
          {formatDate(createdAt, "notification")}
        </Typography>
      </Box>

      {!isRead && <StyledDot />}
    </Box>
  );
}
