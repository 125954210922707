import { Identity } from "types/User";

// Match cookie value set in @communityco/access-tokens
export const AUTHORIZATION_COOKIE = "AuthToken";
export const AUTHORIZATION_HEADER = "x-cco-auth-token";

// role constants
export const ADMIN = "ADMIN";
export const MEMBER = "MEMBER";
export const PUBLIC = "PUBLIC";

// handler for if we cant get an identity
export const DEFAULT_IDENTITY: Identity = {
  hasRole: (role) => role === PUBLIC,
  roles: [PUBLIC],
  userId: undefined,
  isLoggedIn: false,
};
