import init from "remark-parse";

const markdownInit = {
  options: {
    settings: {},
  },
  data(key) {
    const val = this.options[key];
    return val;
  },
  init,
};
markdownInit.init();

export default markdownInit;
