import { gql } from "@apollo/client";

import { USER_FRAGMENT } from "graphql/user/fragments";

export const EXPERT_PANEL_ANSWER_FRAGMENT = gql`
  fragment ExpertPanelAnswer_Fragment on ExpertPanelAnswer {
    id
    answer
    author {
      ...User_Fragment
    }
    createdAt
    likeCount
    hasLiked
    reactionId
    questionId
    question {
      id
      activityId
    }
  }
  ${USER_FRAGMENT}
`;

export const EXPERT_PANEL_QUESTION_FRAGMENT = gql`
  fragment ExpertPanelQuestion_Fragment on ExpertPanelQuestion {
    id
    question
    maxAnswers
    maxAnswerLength
    releaseAt
    activityPreview {
      totalAnswers
      recentAnswerAuthors {
        author {
          ...User_Fragment
        }
      }
    }
    myAnswer {
      hasAnswered
      answer {
        ...ExpertPanelAnswer_Fragment
      }
    }
    endAt
    isEnded
    topicTag {
      id
      slug
      label
    }
  }
  ${USER_FRAGMENT}
  ${EXPERT_PANEL_ANSWER_FRAGMENT}
`;
