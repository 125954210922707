import merge from "lodash/merge";

import { SiteConfig } from "types/site";

const configsByName = {};
const hostsDictionary = {};
const hostWords = [
  "forbes",
  "yec",
  "bizjournals",
  "acbjconnections",
  "rollingstone",
  "fastcompany",
  "vercel",
  "thinktank",
  "kiplinger",
];

function getSiteNameByHost(host) {
  if (!hostsDictionary[host]) {
    for (const word of hostWords) {
      if (host.includes(word)) {
        hostsDictionary[host] = word;
        break;
      }
    }
  }

  return hostsDictionary[host];
}

export default async function getSiteConfig(host): Promise<SiteConfig> {
  const defaultSite = "forbes";
  let data, envData, siteName;
  const CCO_ENV = process.env.CCO_ENV || process.env.VERCEL_ENV;

  if (host === "localhost:3000") {
    siteName = defaultSite;
    if (process.env.RUN_AS_SITE) {
      siteName = process.env.RUN_AS_SITE;
    }
  }
  if (!siteName) {
    siteName = getSiteNameByHost(host);
  }

  if (siteName === "vercel") {
    // revert to default site
    siteName = defaultSite;
  }
  if (!siteName) {
    console.error(`No site config found for ${host}`);
    return null;
  }
  if (!configsByName[siteName]) {
    // lazy load config data by hostname
    data = (await import(`../../../config/${siteName}.ts`)).default;
    try {
      envData = (await import(`../../../config/${siteName}.${CCO_ENV}.ts`))
        .default;
      data = merge(data, envData);
      // eslint-disable-next-line no-empty
    } catch (e) {}
    configsByName[siteName] = data;
  }

  return configsByName[siteName];
}
