import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Typography, useMediaQuery, useTheme } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useState } from "react";

import ContactConciergeForm from "components/shared/ContactConciergeForm";

function Maintenance() {
  const [view, setView] = useState(
    localStorage.getItem("maintenanceRequested") === "true" ? "success" : "form"
  );
  const { permMaintenanceMembershipText } = useFlags();

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box width={isXs ? "100%" : "528px"}>
      <Stack alignItems="center" direction="row" gap="12px" mb="16px">
        <LockOutlinedIcon />
        <Typography variant="subtitle2">Maintenance Membership</Typography>
      </Stack>
      <Typography mb={1} variant="h2">
        {permMaintenanceMembershipText?.title}
      </Typography>

      {view === "form" && (
        <>
          <Typography mb={3} variant="body2">
            {permMaintenanceMembershipText?.defaultMessage}
          </Typography>
          <ContactConciergeForm
            body="Hi, please help me restore my membership"
            context="maintenance"
            handleRequested={() => {
              localStorage.setItem("maintenanceRequested", "true");
              setView("success");
            }}
            subject="Maintenance membership restore request"
          />
        </>
      )}

      {view === "success" && (
        <Typography variant="body2">
          {permMaintenanceMembershipText?.successMessage}
        </Typography>
      )}
    </Box>
  );
}

export default Maintenance;
