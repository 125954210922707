import AccountBoxIcon from "@mui/icons-material/AccountBox";
import AddIcon from "@mui/icons-material/Add";
import ChatIcon from "@mui/icons-material/Chat";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RemoveIcon from "@mui/icons-material/Remove";

import CampaignFilled from "./CampaignFilled";
import Exec from "./Exec";
import Groups from "./Groups";
import LinkedinPrimary from "./LinkedinPrimary";

const ContextMenuIcon = ExpandMoreIcon;
const FollowIcon = AddIcon;
const MessageIcon = ChatIcon;
const ProfileIcon = AccountBoxIcon;
const UnfollowIcon = RemoveIcon;

export {
  CampaignFilled,
  ContextMenuIcon,
  Exec,
  FollowIcon,
  Groups,
  LinkedinPrimary,
  MessageIcon,
  ProfileIcon,
  UnfollowIcon,
};
