/* eslint-disable react/no-danger */
import { cloneDeep } from "lodash";
import Head from "next/head";

import { SiteConfig } from "types/site";

const CONFIG_VAR = "siteConfig";

type Site = {
  config: SiteConfig;
  ConfigHead: React.FC;
};

function cleanConfig(config): SiteConfig {
  const clone = cloneDeep(config);
  delete clone.stream.apiSecret;
  return clone;
}

export default function useSite(getSite): Site {
  const initialState =
    typeof window !== "undefined" ? window[CONFIG_VAR] : getSite();
  const safeConfig = cleanConfig(initialState);

  function ConfigHead() {
    return (
      <Head>
        <script
          dangerouslySetInnerHTML={{
            __html: `var ${CONFIG_VAR}=${JSON.stringify(safeConfig)}`,
          }}
          key="config"
        />
      </Head>
    );
  }

  return { config: safeConfig, ConfigHead };
}
