import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {
  ListItemIcon,
  ListItemIconProps,
  ListItemText,
  ListItemButton as MuiListItemButton,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Link from "next/link";

import useAccess from "hooks/useAccess";

const StyledListItemIcon = styled(ListItemIcon)<ListItemIconProps>(() => ({
  color: "inherit",
  minWidth: "36px",

  "& .MuiSvgIcon-root": {
    fontSize: "1.25rem",
  },
}));

type ListItemButtonProps = {
  className?: string;
  endAdornment?: React.ReactNode;
  icon?: React.ReactNode;
  onClick?: () => void;
  selected?: boolean;
  title: string;
};

function ListItemButton({
  endAdornment,
  icon,
  selected,
  title,
  ...props
}: ListItemButtonProps) {
  return (
    <MuiListItemButton
      selected={selected}
      sx={{
        borderRadius: "4px",
        color: (theme) => theme.palette.text.primary,

        "&.Mui-selected": {
          backgroundColor: (theme) => theme.palette.primary.main,
          color: (theme) => theme.palette.primary.contrastText,

          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.7)",
          },
        },
      }}
      {...props}
    >
      {icon && <StyledListItemIcon>{icon}</StyledListItemIcon>}
      <ListItemText>
        <Typography
          color={(theme) => selected && theme.palette.primary.contrastText}
          variant="body1Semibold"
        >
          {title}
        </Typography>
      </ListItemText>
      {endAdornment}
    </MuiListItemButton>
  );
}

type Props = {
  className?: string;
  endAdornment?: React.ReactNode;
  href?: string;
  icon?: React.ReactNode;
  id?: string;
  onClick?: () => void;
  selected?: boolean;
  title: string;
};

export default function NavigationMenuItem({
  endAdornment,
  href,
  selected,
  title,
  ...props
}: Props) {
  const { hasFeatureAccess } = useAccess();

  const localEndAdornment = !hasFeatureAccess(title) ? (
    <StyledListItemIcon>
      <LockOutlinedIcon />
    </StyledListItemIcon>
  ) : (
    endAdornment
  );

  return href ? (
    <Link href={href}>
      <ListItemButton
        endAdornment={localEndAdornment}
        selected={selected}
        title={title}
        {...props}
      />
    </Link>
  ) : (
    <ListItemButton
      endAdornment={localEndAdornment}
      selected={selected}
      title={title}
      {...props}
    />
  );
}
