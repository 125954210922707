import { useLazyQuery } from "@apollo/client";

import { ME_ONBOARDING_URL_QUERY } from "graphql/me/queries";

export default function useOnboardingUrl() {
  const [getOnboardingUrl, { called, data, loading }] = useLazyQuery(
    ME_ONBOARDING_URL_QUERY
  );

  return {
    called,
    onboardingUrl: data?.me?.onboardingUrl,
    getOnboardingUrl,
    loading,
  };
}
