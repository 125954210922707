import { blue, green, purple, teal } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 768,
      lg: 1080,
      xl: 1280,
    },
  },
  typography: {
    fontFamily: "Inter, Arial, sans-serif",
    h1: {
      fontWeight: 400,
      fontSize: "2.125rem",
      lineHeight: "2.625rem",
      letterSpacing: "0.25px",
    },
    h2: {
      fontWeight: 600,
      fontSize: "1.625rem",
      lineHeight: "2rem",
      letterSpacing: "0px",
    },
    h3: {
      fontWeight: 500,
      fontSize: "1.375rem",
      lineHeight: "2rem",
      letterSpacing: "0px",
    },
    h4: undefined,
    h5: undefined,
    h6: undefined,
    subtitle1: {
      fontWeight: 400,
      fontSize: "1rem",
      lineHeight: "1.5rem",
      letterSpacing: "0px",
    },
    subtitle1Semibold: {
      fontWeight: 600,
      fontSize: "1rem",
      lineHeight: "1.5rem",
      letterSpacing: "0px",
    },
    subtitle2: {
      fontWeight: 500,
      fontSize: "0.875rem",
      lineHeight: "1.5rem",
      letterSpacing: "0px",
    },
    subtitle2Semibold: {
      fontWeight: 600,
      fontSize: "0.875rem",
      lineHeight: "1.5rem",
      letterSpacing: "0px",
    },
    body1: {
      fontWeight: 400,
      fontSize: "1rem",
      lineHeight: "1.5rem",
      letterSpacing: "0.5px",
    },
    body1Semibold: {
      fontWeight: 600,
      fontSize: "1rem",
      lineHeight: "1.5rem",
      letterSpacing: "0.5px",
    },
    body2: {
      fontWeight: 400,
      fontSize: "0.875rem",
      lineHeight: "1.25rem",
      letterSpacing: "0.25px",
    },
    body2Semibold: {
      fontWeight: 600,
      fontSize: "0.875rem",
      lineHeight: "1.25rem",
      letterSpacing: "0.25px",
    },
    caption: {
      fontWeight: 400,
      fontSize: "0.75rem",
      lineHeight: "1.25rem",
      letterSpacing: "0.4px",
    },
    captionSemibold: {
      fontWeight: 600,
      fontSize: "0.75rem",
      lineHeight: "1.25rem",
      letterSpacing: "0.4px",
    },
    overline: undefined,
    button: {
      fontWeight: 600,
      fontSize: "1rem",
      lineHeight: "1.5rem",
      letterSpacing: "1.25px",
      textTransform: "none",
    },
  },
  palette: {
    primary: {
      main: "#333",
      dark: "#000",
      light: "#808080",
      contrastText: "#fff",
    },
    secondary: {
      main: "#0a66c2",
      dark: "#064a8e",
      light: "#6bb2f9",
      contrastText: "#fff",
    },
    success: {
      main: "#4caf50",
      dark: "#388e3c",
      light: "#81c784",
      contrastText: "#fff",
      background: "rgb(76 175 80 / 10%)",
    },
    info: {
      main: "#2196f3",
      dark: "#0b79d0",
      light: "#bbdefb",
      contrastText: "#fff",
    },
    warning: {
      main: "#ff9800",
      dark: "#f57c00",
      light: "#ffb74d",
      contrastText: "#333",
    },
    error: {
      main: "#f44336",
      dark: "#d32f2f",
      light: "#e57373",
      contrastText: "#fff",
    },
    grey: {
      50: "#fafafa",
      100: "#f4f4f4",
      200: "#eeeeee",
      300: "#e0e0e0",
      400: "#bdbdbd",
      500: "#9e9e9e",
      600: "#808080",
      700: "#616161",
      800: "#333333",
      900: "#212121",
      A100: "#d5d5d5",
      A200: "#aaaaaa",
      A400: "#616161",
      A700: "#303030",
    },
    text: {
      primary: "#333",
      secondary: "#808080",
      disabled: "#9e9e9e",
    },
    action: {
      active: "rgba(0, 0, 0, 0.54)",
      hover: "rgba(0, 0, 0, 0.04)",
      selected: "rgba(0, 0, 0, 0.08)",
      disabled: "rgba(0, 0, 0, 0.26)",
      disabledBackground: "rgba(0, 0, 0, 0.12)",
      focus: "rgba(0, 0, 0, 0.12)",
    },
    background: {
      paper: "#fff",
      default: "#fff",
    },
    divider: "rgba(0, 0, 0, 0.12)",
    post: {
      photo: blue,
      video: green,
      news: teal,
      announcement: purple,
    },
  },
});

theme.mixins = {
  toolbar: {
    ...theme.mixins.toolbar,
    minHeight: 64,
  },
};

theme.components = {
  MuiCssBaseline: {
    body: {
      backgroundColor: theme.palette.background.paper,
    },
  },
  MuiAppBar: {
    styleOverrides: {
      colorPrimary: {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
      },
    },
  },
  MuiAccordionSummary: {
    styleOverrides: {
      expandIconWrapper: {
        color: theme.palette.text.primary,
      },
    },
  },
  MuiAvatar: {
    styleOverrides: {
      root: {
        backgroundColor: theme.palette.grey[600],
      },
    },
  },
  MuiAvatarGroup: {
    styleOverrides: {
      avatar: {
        border: "none",
      },
    },
  },
  MuiButton: {
    defaultProps: {
      size: "large",
    },
    styleOverrides: {
      sizeLarge: {
        padding: "8px 16px",
      },
      sizeSmall: {
        fontSize: "14px",
      },
      contained: {
        "&:hover": {
          backgroundColor: "rgba(26, 26, 26, .8)",
        },
      },
      outlined: {
        border: "solid 1px rgba(0,0,0,0.5)",
      },
      link: {
        minWidth: 0,
      },
      root: {
        // Fix for button text color issue in Safari 14.1 (https://github.com/mui-org/material-ui/issues/26251)
        transition: "color .01s",
        ...theme.typography.button,
        whiteSpace: "nowrap",
      },
    },
    variants: [
      {
        props: { variant: "link" },
        style: {
          ...theme.typography.body2,
          minWidth: 0,
          padding: 0,
          textTransform: "none",
          color: theme.palette.secondary.main,

          "&:hover": {
            backgroundColor: "transparent",
          },
        },
      },
    ],
  },
  MuiCardContent: {
    styleOverrides: {
      root: {
        padding: theme.spacing(1, 2),
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        ...theme.typography.body2Semibold,
      },
      sizeSmall: {
        fontSize: "13px",
        lineHeight: "18px",
        letterSpacing: "0.16px",
      },
    },
  },
  MuiDialog: {
    styleOverrides: {
      paperScrollPaper: {
        maxHeight: "calc(100% - 100px)",
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
        gap: theme.spacing(1),
        "& > :not(style) ~ :not(style)": {
          marginLeft: "0px",
        },
      },
    },
  },
  MuiFab: {
    styleOverrides: {
      root: ({ ownerState }) => ({
        ...(ownerState.color === "primary" && {
          "&:hover": {
            backgroundColor: "rgba(26, 26, 26, .8)",
          },
        }),
      }),
    },
    variants: [
      {
        props: { variant: "ghost" },
        style: {
          backgroundColor: "rgba(0,0,0,0.6)",
          color: theme.palette.primary.contrastText,

          "&:hover": {
            backgroundColor: theme.palette.primary.main,
          },
        },
      },
    ],
  },
  MuiListItemIcon: {
    styleOverrides: {
      root: {
        color: theme.palette.text.primary,
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        color: theme.palette.text.primary,
      },
      colorPrimary: {
        "&:hover": {
          backgroundColor: "#000",
        },
        backgroundColor: "#333",
        color: "#fff",
      },
    },
  },
  MuiLink: {
    defaultProps: {
      color: "initial",
    },
  },
  MuiListItem: {
    styleOverrides: {
      root: {
        borderRadius: "4px",
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      root: {
        "& fieldset": {
          borderColor: theme.palette.grey[800],
        },
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        ...theme.typography.subtitle1,
        color: theme.palette.text.primary,
        textTransform: "none",
        fontWeight: 600,
        transition: `background-color ${theme.transitions.duration.shortest}ms ${theme.transitions.easing.easeInOut}, color ${theme.transitions.duration.shortest}ms ${theme.transitions.easing.easeInOut}`,

        "&.Mui-selected": {
          color: theme.palette.primary.contrastText,
          backgroundColor: theme.palette.primary.main,
        },

        "&:hover:not(.Mui-selected)": {
          backgroundColor: theme.palette.grey[300],
        },
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      root: {
        background: theme.palette.grey[100],
      },
      indicator: {
        display: "none",
      },
    },
    defaultProps: {
      variant: "scrollable",
      scrollButtons: "auto",
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        "& .MuiOutlinedInput-root": {
          "&.MuiInputBase-sizeSmall": {
            "& .MuiIconButton-root": {
              margin: "-5px",
            },
          },

          "& fieldset": {
            borderColor: theme.palette.grey[800],
          },
        },
        "& input::placeholder, & textarea::-webkit-input-placeholder": {
          color: theme.palette.grey[600],
          opacity: 1,
        },
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        lineHeight: "14px",
        backgroundColor: theme.palette.text.primary,
      },
      arrow: {
        color: theme.palette.text.primary,
      },
      tooltipPlacementBottom: {
        '&.MuiTooltip-popper[data-popper-placement*="bottom"] .MuiTooltip-tooltip':
          {
            marginTop: 0,
          },
      },
    },
  },
  MuiTypography: {
    styleOverrides: {
      root: {
        "& .MuiLink-root": {
          color: "#0b79d0",
        },
      },
    },
    defaultProps: {
      variantMapping: {
        subtitle1Semibold: "h6",
        subtitle2Semibold: "h6",
        body1Semibold: "p",
        body2Semibold: "p",
      },
    },
  },
};

export default theme;
