import { connect as connectStream } from "getstream/lib/connect";
import { useEffect, useState } from "react";

export default function useStreamFeedClient(token, config) {
  const streamApiKey = config.apiKey;
  const streamAppId = config.appId;

  const [client, setClient] = useState();

  useEffect(() => {
    if (token) {
      const newClient = connectStream(streamApiKey, token, streamAppId);
      setClient(newClient);
    }
  }, [streamApiKey, streamAppId, token]);

  return client;
}
