import { gql } from "@apollo/client";

export const COMMUNITY_QUERY = gql`
  query community($code: String!) {
    community(code: $code) {
      name
      headerLogo
      conciergeEmail
      execLink
      execMSRP
      links {
        membershipInfo
      }
      socialMediaLinks {
        facebook
        twitter
        linkedin
        instagram
      }
      navMore {
        label
        link
        newWindow
      }
    }
  }
`;

export const COMMUNITIES_PUBLIC_QUERY = gql`
  query communitiesPublic {
    communitiesPublic {
      code
      familiarName
    }
  }
`;
