import { Box } from "@mui/material";
import NextImage, { ImageProps } from "next/legacy/image";

import externalImageLoader from "lib/common/externalImageLoader";

type Props = {
  width: string | number;
  height: string | number;
  externalSrc?: boolean;
} & ImageProps;

export default function Image({ width, height, externalSrc, ...props }: Props) {
  const imageProps = {
    height,
    width,
    ...props,
  };

  if (externalSrc) {
    imageProps.loader = (loaderProps) =>
      externalImageLoader({
        src: loaderProps.src,
        width,
        height,
        srcSetWidth: loaderProps.width,
      });
  }

  // If the image is "fixed" i.e. exactly the given height & width, the wrapper isn't needed
  if (props?.layout === "fixed") {
    return <NextImage {...imageProps} />;
  }

  return (
    <Box
      height="100%"
      position="relative"
      sx={{
        "& span:first-child": {
          height: "100% !important",
          width: "100% !important",
        },
      }}
    >
      <NextImage {...imageProps} />
    </Box>
  );
}
