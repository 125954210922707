import { useQuery } from "@apollo/client";

import { COMMUNITY_QUERY } from "graphql/community/queries";

export default function useCommunity({ code }) {
  const { data, loading, error } = useQuery(COMMUNITY_QUERY, {
    variables: { code },
  });

  return {
    community: data?.community,
    loading,
    error,
  };
}
